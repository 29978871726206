import { DayOfWeek, LocalDate, ZoneId } from "@js-joda/core";
import { omit } from "lodash";
import { ApiErrors } from "../consts/apiErrors.const";
import { AssertNever } from "../consts/assertNever.const";
import { DateUtils } from "../consts/dateUtils";
import { loadable, Loadable } from "../consts/loadable.const";
import {
  OfficeId,
  PatientId,
  PlanOfCareId,
  PlanOfCareItemId,
  PlanOfCareTypeId,
} from "../messages/ids";
import {
  PatientPlanOfCare,
  PatientPlansOfCareDuty,
  PlanOfCareDutyPeriod,
} from "../messages/plan_of_care";
import { PlanOfCareType } from "../messages/plan_of_care_type";
import { PlanOfCareService } from "../services/planOfCareService";
import { PlanOfCareTypeService } from "../services/planOfCareTypeService";
import { MfModalFactory } from "../services/mfModalFactory";
import { isDefined } from "../utils/generalUtils";
import { NEW_YORK_TZ } from "../../../../webapp-react/src/shared/utils/date-utils";

interface FormDuty {
  days: Set<DayOfWeek>;
  period: PlanOfCareDutyPeriod | null;
  frequency: { id: number | null };
  visitsPerWeek: { id: number | null };
  notes: string | null;
}

interface Form {
  startDate: {
    value: Date | null;
    isOpen: boolean;
  };
  endDate: {
    value: Date | null;
    isOpen: boolean;
  };
  duties: Map<PlanOfCareItemId, FormDuty>;
}

interface ValidFormDutyState {
  days: Set<DayOfWeek>;
  period: PlanOfCareDutyPeriod;
  frequency: number | null;
  visitsPerWeek: number | null;
  notes: string | null;
}

interface ValidFormState {
  startDate: Date;
  endDate: Date | null;
  duties: Map<PlanOfCareItemId, ValidFormDutyState>;
}

export type InitialFormData = Partial<{
  startDate: LocalDate | null;
  endDate: LocalDate | null;
  selectedPlanOfCare: PatientPlanOfCare;
  duties: Map<PlanOfCareItemId, FormDuty>;
}>;

//! @ngInject
export class NewPlanOfCareModalCtrl implements ng.IController {
  static readonly $name = "newPlanOfCareModalCtrl";

  constructor(
    private $timeout: ng.ITimeoutService,
    private $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    private mfModal: MfModalFactory,
    private toaster: toaster.IToasterService,
    private planOfCareService: PlanOfCareService,
    private planOfCareTypeService: PlanOfCareTypeService,
    private apiErrors: ApiErrors,
    private dateUtils: DateUtils,
    private assertNever: AssertNever,
    private initialFormData: InitialFormData | null,
    private patientId: PatientId,
    private patientOfficeId: OfficeId
  ) {}

  planOfCareType: Loadable<PlanOfCareType | null> = loadable.loading();
  form!: Form;
  overrideFromSelectedPlanOfCare: boolean = false;
  isSubmitting = false;

  // read from the template
  readonly altInputFormats = ["MM/dd/yyyy"];
  readonly frequencyOptions = [1, 2, 3, 4, 5, 6, 7].map((i) => ({ id: i, label: i }));

  $onInit() {
    this.setInitialForm(this.initialFormData);

    this.planOfCareTypeService
      .getByOfficeId({ officeId: this.patientOfficeId })
      .then((planOfCareType) => {
        this.planOfCareType = loadable.resolve(
          planOfCareType === null
            ? null
            : {
                ...planOfCareType,
                columns: planOfCareType.columns.filter((x) => x.type === "Task"),
              }
        );
      });
  }

  handleSubmit() {
    const validation = this.validateForm();

    // this condition should not happen in runtime, but keeping it for type safety
    if (!loadable.isResolved(this.planOfCareType) || this.planOfCareType.value === null) {
      console.warn("Plan of care type is unavailable");
      return;
    }

    // I'm not sure why planOfCareTypeId is nullable, but making sure the user will receive an input
    if (this.planOfCareType.value.planOfCareTypeId === null) {
      this.toaster.error("Plan of care type is unavailable");
      return;
    }

    if (validation.type === "Error") {
      this.toaster.error(validation.message);
      return;
    }

    const planOfCareTypeId = this.planOfCareType.value.planOfCareTypeId;

    const { state } = validation;

    this.isSubmitting = true;

    const selectedPlanOfCare = this.initialFormData?.selectedPlanOfCare;
    if (this.overrideFromSelectedPlanOfCare && isDefined(selectedPlanOfCare)) {
      const submittedDate = selectedPlanOfCare.submittedAt
        .atZone(ZoneId.of(NEW_YORK_TZ))
        .toLocalDateTime();
      const modal = this.mfModal.create({
        confirmLabel: "OK",
        variant: "danger",
        message: `This POC will have the signature of ${
          selectedPlanOfCare.signeeName
        } for date ${this.dateUtils.localDateTimeToMDYHMString(submittedDate)}`,
        subject: "Copy Signatures And Dates",
        onConfirm: () => {
          this.submitCreatePlanOfCare(planOfCareTypeId, state, selectedPlanOfCare.planOfCareId);
          modal.close();
        },
        onCancel: () => {
          this.isSubmitting = false;
          modal.close();
        },
      });
    } else {
      this.submitCreatePlanOfCare(planOfCareTypeId, state);
    }
  }

  submitCreatePlanOfCare(
    planOfCareTypeId: PlanOfCareTypeId,
    state: ValidFormState,
    overrideFromPlanOfCareId?: PlanOfCareId
  ) {
    return this.planOfCareService
      .create({
        startDate: this.dateUtils.dateToLocalDate(state.startDate),
        endDate: state.endDate !== null ? this.dateUtils.dateToLocalDate(state.endDate) : null,
        duties: Array.from(state.duties.entries()).map(([id, duty]) => ({
          planOfCareItemId: id,
          period: duty.period,
          daysAWeek: [...duty.days],
          frequency: duty.frequency,
          visitsPerWeek: duty.visitsPerWeek,
          notes: duty.notes,
        })),
        patientId: this.patientId,
        planOfCareTypeId: planOfCareTypeId,
        overrideSignatureAndDateFromPlanOfCareId: overrideFromPlanOfCareId,
      })
      .then(() => {
        this.toaster.success("Plan of care created");
        this.$uibModalInstance.close("success");
      })
      .catch((error) => {
        this.$timeout(
          () => this.toaster.error(this.apiErrors.format(error, "Failed to create plan of care")),
          0
        );
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  handleCheckDay(params: { id: PlanOfCareItemId; day: DayOfWeek }) {
    const duty = this.form.duties.get(params.id) ?? null;

    // This shouldn't happen in the UI
    if (duty === null) {
      this.toaster.error("Can't check day on unselected duty");
      return;
    }
    const days = new Set(duty.days);
    days.has(params.day) ? days.delete(params.day) : days.add(params.day);

    this.updateSelectedDuty({
      id: params.id,
      period: "DAYS_A_WEEK",
      days: days,
    });
  }

  handleChangePeriod(params: { id: PlanOfCareItemId; period: PlanOfCareDutyPeriod }) {
    switch (params.period) {
      case "EVERY_VISIT":
      case "AS_REQUESTED":
        return this.updateSelectedDuty({
          id: params.id,
          period: params.period,
          days: getInitialItem().days,
          frequency: getInitialItem().frequency,
          visitsPerWeek: getInitialItem().visitsPerWeek,
        });
      case "DAYS_A_WEEK":
        return this.updateSelectedDuty({
          id: params.id,
          period: params.period,
        });
      default:
        this.assertNever(params.period);
    }
  }

  handleCheckItem(params: { id: PlanOfCareItemId; checked: boolean }) {
    if (!params.checked) {
      this.form.duties.delete(params.id);
      return;
    }

    this.form.duties.set(params.id, getInitialItem());
  }

  private updateSelectedDuty(params: { id: PlanOfCareItemId } & Partial<FormDuty>) {
    const newChanges = omit(params, "id");
    const selectedDuty = this.form.duties.get(params.id) ?? null;

    this.form.duties.set(params.id, {
      ...getInitialItem(),
      ...selectedDuty,
      ...newChanges,
    });
  }

  private validateForm():
    | { type: "Error"; message: string }
    | { type: "Success"; state: ValidFormState } {
    if (!loadable.isResolved(this.planOfCareType)) {
      return { type: "Error", message: "Plan of care type is loading" };
    }

    if (this.form.startDate.value === null) {
      return {
        type: "Error",
        message: "Start date is required",
      };
    }

    if (this.form.duties.size === 0) {
      return {
        type: "Error",
        message: "At least one duty is required",
      };
    }

    const validDuties: Map<PlanOfCareItemId, ValidFormDutyState> = new Map();

    for (const [id, duty] of this.form.duties.entries()) {
      const item = (this.planOfCareType.value?.columns ?? []).find((i) => i.id === id) ?? null;

      if (item === null) {
        return {
          type: "Error",
          message: `Unknown item id: ${id}`,
        };
      }

      if (duty.period === null) {
        return {
          type: "Error",
          message: `Period is required for ${item.label}`,
        };
      }

      switch (duty.period) {
        case "AS_REQUESTED":
        case "EVERY_VISIT":
          break;
        case "DAYS_A_WEEK":
          if (duty.frequency === null || duty.visitsPerWeek === null) {
            return {
              type: "Error",
              message: `Missing frequency data for ${item.label}`,
            };
          }
          break;
        default:
          this.assertNever(duty.period);
      }

      validDuties.set(id, {
        period: duty.period,
        days: duty.days,
        frequency: "id" in duty.frequency ? duty.frequency.id : null,
        visitsPerWeek: "id" in duty.visitsPerWeek ? duty.visitsPerWeek.id : null,
        notes: duty.notes,
      });
    }

    return {
      type: "Success",
      state: {
        duties: validDuties,
        startDate: this.form.startDate.value,
        endDate: this.form.endDate.value,
      },
    };
  }

  private setInitialForm(initialFormData: InitialFormData | null) {
    this.form = {
      startDate: {
        value:
          initialFormData?.startDate instanceof LocalDate
            ? this.dateUtils.localDateToDate(initialFormData.startDate)
            : null,
        isOpen: false,
      },
      endDate: {
        value:
          initialFormData?.endDate instanceof LocalDate
            ? this.dateUtils.localDateToDate(initialFormData.endDate)
            : null,
        isOpen: false,
      },
      duties: new Map<PlanOfCareItemId, FormDuty>(
        initialFormData !== null && initialFormData.duties instanceof Map
          ? [...initialFormData.duties.entries()].map(([id, duty]) => [
              id,
              {
                days: new Set(duty.days),
                period: duty.period,
                frequency: { id: duty.frequency.id ?? null },
                visitsPerWeek: { id: duty.visitsPerWeek.id ?? null },
                notes: duty.notes,
              },
            ])
          : []
      ),
    };
  }
}

export function mapPlanOfCareItemIdsToFormDuties(
  planOfCareItemIds: PlanOfCareItemId[]
): Map<PlanOfCareItemId, FormDuty> {
  return planOfCareItemIds.reduce((acc, id) => {
    acc.set(id, getInitialItem());
    return acc;
  }, new Map<PlanOfCareItemId, FormDuty>());
}

export function mapPatientPlansOfCareDutiesToFormDuties(
  patientPlansOfCareDuties: PatientPlansOfCareDuty[]
): Map<PlanOfCareItemId, FormDuty> {
  return patientPlansOfCareDuties.reduce(
    (acc, duty) => {
      return acc.set(duty.item.id, transformDuty(duty));
    },
    new Map<PlanOfCareItemId, FormDuty>()
  );
}

function getInitialItem(): FormDuty {
  return {
    days: new Set(),
    period: "EVERY_VISIT",
    frequency: { id: null },
    visitsPerWeek: { id: null },
    notes: null
  };
}

function transformDuty(duty: PatientPlansOfCareDuty): FormDuty {
  return {
    days: new Set(duty.daysOfWeek),
    period: duty.period,
    frequency: { id: duty.frequency },
    visitsPerWeek: { id: duty.visitsPerWeek },
    notes: duty.notes
  };
}
