import { loadable, Loadable } from "../consts/loadable.const";
import {
  InitialFormData,
  mapPatientPlansOfCareDutiesToFormDuties,
  NewPlanOfCareModalCtrl,
} from "../controllers/newPlanOfCareModalCtrl";
import { PatientId, PlanOfCareId } from "../messages/ids";
import { PatientExtendedData } from "../messages/patient";
import { PatientPlanOfCare } from "../messages/plan_of_care";
import { Api } from "../services/Api";
import { Endpoint } from "../services/endpoint.service";
import { PatientService } from "../services/patientService";
import { PlanOfCareDutyTableRow, PlanOfCareService } from "../services/planOfCareService";

interface Bindings {
  patientId: PatientId;
  selectedPlanOfCareId?: PlanOfCareId;
  sendEmail: (params: { document: { id: number }; type: "planOfCare" }) => void;
}

interface ComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof Bindings, string>;
}

//! @ngInject
class PatientPlansOfCareCtrl implements ng.IComponentController, Bindings {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private $timeout: ng.ITimeoutService,
    private NgTableParams: NgTable.ITableParamsConstructor<PlanOfCareDutyTableRow>,
    private toaster: toaster.IToasterService,
    private endpoint: Endpoint,
    private api: Api,
    private patientService: PatientService,
    private planOfCareService: PlanOfCareService
  ) {}

  //#region bindings
  patientId!: PatientId;
  sendEmail!: (params: { document: { id: number | PlanOfCareId }; type: "planOfCare" }) => void;
  selectedPlanOfCareId: PlanOfCareId | undefined = undefined;
  //#endregion

  patient: Loadable<PatientExtendedData> = loadable.loading();
  plansOfCare: Loadable<PatientPlanOfCare[]> = loadable.loading();
  table: NgTableParams<PlanOfCareDutyTableRow> | null = null;
  selectedPlanOfCare: PatientPlanOfCare | null = null;
  signature: Loadable<string | null> = loadable.loading();
  checked = true;

  $onInit() {
    this.setInitialData();
  }

  handleClickNewPlanOfCare() {
    if (!loadable.isResolved(this.signature) || typeof this.signature.value !== "string") {
      this.toaster.warning("Signature is required. please add it in the profile.");
      return;
    }

    if (!loadable.isResolved(this.patient)) {
      console.warn("Patient is still loading");
      return;
    }

    const { currentOfficeId } = this.patient.value;

    this.$uibModal
      .open({
        templateUrl: "admin/views/new-plan-of-care-modal.html",
        size: "lg",
        controllerAs: "ctrl",
        controller: NewPlanOfCareModalCtrl.$name,
        resolve: {
          initialFormData: (): InitialFormData | null => ({
            startDate: this.selectedPlanOfCare?.endDate?.plusDays(1),
            duties: mapPatientPlansOfCareDutiesToFormDuties(this.selectedPlanOfCare?.duties ?? []),
            selectedPlanOfCare: this.selectedPlanOfCare ?? undefined,
          }),
          patientId: () => this.patientId,
          patientOfficeId: () => currentOfficeId,
        },
      })
      .result.then((res) => {
        console.log(res);
        if (res === "success") {
          this.fetchPlansOfCare();
          this.$rootScope.$emit("patient_poc_updated");
        }
      });
  }

  handleClickViewPDF() {
    if (this.selectedPlanOfCare === null) {
      console.warn("No plan of care selected");
      return;
    }

    this.planOfCareService
      .getPdfUrl(this.selectedPlanOfCare.planOfCareId)
      .then((pdfUrl) => {
        if (pdfUrl === null) {
          this.toaster.warning("No PDF available");
          return;
        }

        window.open(pdfUrl);
      })
      .catch((error) => {
        console.error(error);
        this.toaster.error({ body: "Error while loading pdf" });
      });
  }

  handleClickViewHistory() {
    if (!loadable.isResolved(this.patient)) {
      console.warn("Patient is still loading");
      return;
    }

    return this.$rootScope.openPatientPocHistoryModal(this.patient.value);
  }

  handleClickSendEmail() {
    if (this.selectedPlanOfCare === null) {
      this.toaster.warning("No plan of care selected");
      return;
    }

    if (this.sendEmail !== undefined) {
      this.sendEmail({
        type: "planOfCare",
        document: { id: this.selectedPlanOfCare.planOfCareId },
      });
    }
  }

  get canSendAsEmail() {
    return this.sendEmail !== undefined;
  }

  get isLoading() {
    return (
      loadable.isLoading(this.patient) ||
      loadable.isLoading(this.plansOfCare) ||
      loadable.isLoading(this.signature)
    );
  }

  // We should probably move this method to a different service
  private async getCoordinatorSignature() {
    const url = this.endpoint({
      path: "agencies/:agencyId/coordinators/:agencyMemberId/signature",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<{ url: string | null }>(url).then(({ data }) => data.url);
  }

  setTable(items: PlanOfCareDutyTableRow[]) {
    this.table = new this.NgTableParams(
      {
        count: 15,
      },
      {
        counts: [15, 25, 50, 100],
        dataset: items,
      }
    );
  }

  setSelectedPlanOfCare(planOfCare: PatientPlanOfCare | null) {
    if (planOfCare === null) {
      this.selectedPlanOfCare = null;
      this.setTable([]);
      return;
    }

    this.selectedPlanOfCare = planOfCare;
    this.setTable(
      this.selectedPlanOfCare.duties.map((x) => this.planOfCareService.mapToTableRow(x))
    );
  }

  setInitialData() {
    this.fetchPatient();
    this.fetchPlansOfCare();
    this.fetchCoordinatorSignature();
  }

  private fetchPatient() {
    this.patientService
      .getById(this.patientId)
      .then((patient) => (this.patient = loadable.resolve(patient)));
  }

  private async fetchPlansOfCare() {
    const plansOfCare = await this.planOfCareService.getByPatientId(this.patientId);

    const selectedPlanOfCare =
      this.selectedPlanOfCareId !== undefined
        ? plansOfCare.find((x) => x.planOfCareId === this.selectedPlanOfCareId)
        : (plansOfCare[0] as PatientPlanOfCare | undefined);

    if (selectedPlanOfCare !== undefined) {
      this.setSelectedPlanOfCare(selectedPlanOfCare);
    }

    this.$timeout(() => (this.plansOfCare = loadable.resolve(plansOfCare)), 0);
  }

  private fetchCoordinatorSignature() {
    this.getCoordinatorSignature().then((signature) =>
      this.$timeout(() => (this.signature = loadable.resolve(signature)))
    );
  }
}

export const patientPlansOfCareComponent: ComponentOptions = {
  controller: PatientPlansOfCareCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/views/patient-plans-of-care.html",
  bindings: {
    patientId: "<",
    selectedPlanOfCareId: "<",
    sendEmail: "&",
  },
};
