import { Flex, useToast } from "@chakra-ui/react";
import useApi from "../../shared/hooks/useApi";
import { PatientId, PhysicianId, PhysicianPatientAssocId } from "../../shared/schema/schema";
import { PatientPrimaryPhysician } from "./PatientPrimaryPhysician.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Select from "../../shared/components/Select";

export default function PatientPhysicianEdit(props: {
  patientId: PatientId;
  physician: PatientPrimaryPhysician | null;
}) {
  const { queries, api } = useApi();
  const queryClient = useQueryClient();
  const availablePhysiciansQuery = useQuery(
    queries.patientPhysician.getAvailablePhysician(props.patientId)
  );
  const toaster = useToast({
    position: "top-right",
    duration: 2000,
  });

  const updatePrimaryPhysicianMutation = useMutation({
    mutationFn: (physicianId: PhysicianId) => {
      return api.post("./patients/:patientId/physicians", {
        body: {
          physicianId: physicianId,
          isPrimary: true,
        },
        path: {
          patientId: props.patientId,
        },
      });
    },
    onSuccess: () => {
      toaster({
        status: "success",
        title: "Primary Physician Updated",
      });
      queryClient.invalidateQueries(
        queries.patientPhysician.getAvailablePhysician(props.patientId)
      );
      queryClient.invalidateQueries(queries.patientPhysician.getPrimaryPhysician(props.patientId));
    },
  });

  const deletePrimaryPhysicianMutation = useMutation({
    mutationFn: (physicianAssocId: PhysicianPatientAssocId) => {
      return api.delete("./patients/:patientId/patient_physicians/:physicianPatientAssocId", {
        path: {
          patientId: props.patientId,
          physicianPatientAssocId: physicianAssocId,
        },
      });
    },
    onSuccess: () => {
      toaster({
        status: "success",
        title: "Primary Physician Removed",
      });
      queryClient.invalidateQueries(
        queries.patientPhysician.getAvailablePhysician(props.patientId)
      );
      queryClient.invalidateQueries(queries.patientPhysician.getPrimaryPhysician(props.patientId));
    },
  });

  const physicians = availablePhysiciansQuery.data?.physicians ?? [];

  return (
    <Flex direction="column" w="full">
      <Select
        isLoading={
          deletePrimaryPhysicianMutation.isPending || updatePrimaryPhysicianMutation.isPending
        }
        label="Select Physician"
        multiple={false}
        options={physicians.map((p) => ({
          value: p.id,
          label: `${p.firstName} ${p.lastName}, NPI: ${p.npi}`,
        }))}
        value={props.physician?.id ?? null}
        onChange={(physicianId) => {
          if (physicianId === undefined) {
            if (props.physician?.physicianAssocId !== undefined) {
              deletePrimaryPhysicianMutation.mutate(props.physician?.physicianAssocId);
            }
          } else {
            updatePrimaryPhysicianMutation.mutate(physicianId);
          }
        }}
      />
    </Flex>
  );
}
