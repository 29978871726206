import { Messages } from "../../core/api";
import { phoneFormatter } from "./phone-formatter";

export const getLabelForNotIdentifiedPhoneNumber = (
  notIdentifiedType: Messages["TelephonyNotIdentifiedKind"] | null
) => {
  switch (notIdentifiedType) {
    case "NotIdentifiedCaregiver":
      return "Not Identified Caregiver";
    case "NotIdentifiedPatient":
      return "Not Identified Patient";
    case "NotIdentifiedInsuranceProvider":
      return "Not Identified Provider";
    case "NotIdentifiedReferralUser":
      return "Not Identified Referral User";
    case "NotIdentifiedPhoneNumber":
    case null:
      return "Not Identified";
  }
};

export const formatPhoneNumber = (input: string) => {
  if (phoneFormatter.isValidNumber(input)) {
    return phoneFormatter.formatNational(input);
  }

  return input;
};
