import { DayOfWeek, LocalTime, LocalDate, Instant, LocalDateTime } from "@js-joda/core";
import { ServiceCodeId, PayrollCodeId, PatientContractId, PatientTaskId, PatientTaskInstanceId, CaregiverId, PatientId, PlanOfCareTypeId, AgencyMemberId, UserId, PatientDocumentTypeId, PatientTaskTemplateId, OfficeId, PatientDocumentScheduleId, PlanOfCareId, CaregiverCertification } from "./ids";

export type PatientTaskTemplateContext = "START_OF_CARE" | "REASSESSMENT";
export type TaskDayTimeParams = null | TaskDayTimeParam[];
export interface TaskDayTimeParam {
    dayOfWeek: DayOfWeek;
    startTime: LocalTime;
    endTime: LocalTime;
}

export type PatientTaskTypesForWebapp =
    | PatientTaskTypes.FutureTask
    | PatientTaskInstanceWithDetails
    | PatientTaskTypes.DraftTask
    | PatientTaskTypes.Broadcasting;
export type PatientTaskTypes =
    | PatientTaskTypes.FutureTask
    | PatientTaskTypes.RegularTask
    | PatientTaskTypes.DraftTask;

export namespace PatientTaskTypes {
    export interface Broadcasting {
        type: "Broadcasting";
        serviceCodeId: ServiceCodeId;
        payrollCodeId: PayrollCodeId | null;
        patientContractId: PatientContractId;
        taskId: PatientTaskId;
        title: string;
        durationMinutes: number | null;
        taskInstanceId: PatientTaskInstanceId;
        repeatMonths: number | null;
        caregiverId: CaregiverId | null;
        patientId: PatientId;
        patientName: string;
        startDate: LocalDate;
        dueDate: LocalDate;
        planOfCareTypeId: PlanOfCareTypeId | null;
        createdAt: Instant;
        createdBy: AgencyMemberId;
        updatedAt: Instant | null;
        updatedBy: UserId | null;
        documents: PatientDocumentTypeId[];
        taskTemplateId: PatientTaskTemplateId;
        broadcastDistance: number | null;
        allowedCertifications: CaregiverCertification[];
        dayTimeParams: TaskDayTimeParams;
        patientOfficeId: OfficeId;
        certificationStartDate: LocalDate | null;
        certificationEndDate: LocalDate | null;
        taskTemplateContext: PatientTaskTemplateContext | null;
    }
    export interface FutureTask {
        type: "FutureTask";
        serviceCodeId: ServiceCodeId;
        payrollCodeId: PayrollCodeId | null;
        patientContractId: PatientContractId;
        taskId: PatientTaskId;
        title: string;
        patientId: PatientId;
        durationMinutes: number | null;
        patientName: string;
        repeatMonths: number | null;
        startDate: LocalDate;
        dueDate: LocalDate;
        documents: PatientDocumentTypeId[];
        planOfCareTypeId: PlanOfCareTypeId | null;
        taskTemplateId: PatientTaskTemplateId;
        allowedCertifications: CaregiverCertification[];
        dayTimeParams: TaskDayTimeParams;
        patientOfficeId: OfficeId;
        taskTemplateContext: PatientTaskTemplateContext | null;
    }

    export interface DraftTask {
        type: "DraftTask";
        serviceCodeId: ServiceCodeId;
        payrollCodeId: PayrollCodeId | null;
        patientContractId: PatientContractId;
        taskId: PatientTaskId;
        patientId: PatientId;
        patientName: string;
        title: string;
        durationMinutes: number | null;
        repeatMonths: number | null;
        caregiverId: CaregiverId | null;
        planOfCareTypeId: PlanOfCareTypeId | null;
        isDraft: true;
        startDate: LocalDate;
        dueDate: LocalDate;
        createdAt: Instant;
        createdBy: AgencyMemberId;
        updatedAt: Instant | null;
        updatedBy: AgencyMemberId | null;
        removedAt: Instant | null;
        removedBy: AgencyMemberId | null;
        documents: PatientDocumentTypeId[];
        taskTemplateId: PatientTaskTemplateId;
        taskCanceledAt: Instant | null;
        taskCanceledBy: AgencyMemberId | null;
        allowedCertifications: CaregiverCertification[];
        dayTimeParams: TaskDayTimeParams;
        patientOfficeId: OfficeId;
        taskTemplateContext: PatientTaskTemplateContext | null;
    }

    export interface RegularTask {
        type: "RegularTask";
        serviceCodeId: ServiceCodeId;
        payrollCodeId: PayrollCodeId | null;
        patientContractId: PatientContractId;
        taskId: PatientTaskId;
        title: string;
        durationMinutes: number | null;
        taskInstanceId: PatientTaskInstanceId;
        repeatMonths: number | null;
        patientTaskInstanceId: PatientTaskInstanceId;
        caregiverId: CaregiverId | null;
        patientId: PatientId;
        patientName: string;
        startDate: LocalDate;
        dueDate: LocalDate;
        planOfCareTypeId: PlanOfCareTypeId | null;
        completionDate: LocalDate | null;
        scheduleDateTime: Instant | null;
        createdAt: Instant;
        createdBy: AgencyMemberId;
        updatedAt: Instant | null;
        updatedBy: UserId | null;
        removedAt: Instant | null;
        removedBy: AgencyMemberId | null;
        documents: PatientDocumentTypeId[];
        taskTemplateId: PatientTaskTemplateId;
        taskCanceledAt: Instant | null;
        taskCanceledBy: AgencyMemberId | null;
        taskInstanceCanceledAt: Instant | null;
        taskInstanceCanceledBy: AgencyMemberId | null;
        broadcastDistance: number | null;
        isBroadcasting: boolean;
        allowedCertifications: CaregiverCertification[];
        dayTimeParams: TaskDayTimeParams;
        patientOfficeId: OfficeId;
        certificationStartDate: LocalDate | null;
        certificationEndDate: LocalDate | null;
        taskTemplateContext: PatientTaskTemplateContext | null;
    }
}


export interface PatientTaskInstanceWithDetails
    extends Omit<PatientTaskTypes.RegularTask, "documents"> {
    documents: {
        documentTypeId: PatientDocumentTypeId;
        documentScheduleId: PatientDocumentScheduleId | null;
        title: string;
        submitted: boolean;
        isScanned: boolean;
        fileUrl: string | null;
    }[];
    planOfCare?: {
        planOfCareId: PlanOfCareId;
        title: string;
        submitted: boolean;
        fileUrl: string | null;
    };
    caregiver: {
        id: CaregiverId;
        fullName: string;
    } | null;
    hasClockedIn: boolean;
    patientConfirmedSchedule: boolean;
}

export interface PatientTaskInstanceEditScheduleDateTime {
  scheduledDateTime: LocalDateTime;
  duration: number;
}

export const TaskTemplateContext = {
    StartOfCare: "START_OF_CARE",
    Reassessment: "REASSESSMENT",
    Regular: null,
};
  