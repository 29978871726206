import { Flex, FormLabel, Text } from "@chakra-ui/react";
import { PatientPrimaryPhysician } from "./PatientPrimaryPhysician.types";

export function PatientPhysicianDisplay(props: { physician: PatientPrimaryPhysician | null }) {
  return props.physician === null ? (
    <Flex>no physician selected</Flex>
  ) : (
    <Flex direction="column" gap={8} w="full">
      <Flex direction="row" gap={4}>
        <Flex direction="column">
          <FormLabel>First Name</FormLabel>
          <Text>{props.physician.firstName}</Text>
        </Flex>
        <Flex direction="column">
          <FormLabel>Last Name</FormLabel>
          <Text>{props.physician.lastName}</Text>
        </Flex>

        <Flex direction="column">
          <FormLabel>NPI</FormLabel>
          <Text>{props.physician.npi ?? "N/A"}</Text>
        </Flex>
        <Flex direction="column">
          <FormLabel>Fax Number</FormLabel>
          <Text>{props.physician.faxNumber ?? "N/A"}</Text>
        </Flex>
        <Flex direction="column">
          <FormLabel>Phone Number</FormLabel>
          <Text>{props.physician.phone ?? "N/A"}</Text>
        </Flex>
        <Flex direction="column">
          <FormLabel>Address</FormLabel>
          <Text>{props.physician.address ?? "N/A"}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
