import { Divider, Flex, Heading, List, ListItem, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import TextDocumentOutlineIcon from "../../../shared/icons/TextDocumentOutlineIcon";
import { Messages, ResponseOf } from "../../../core/api";
import { fmap, sortWith } from "../../../shared/utils";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import React from "react";

type PortalDocumentRow = ResponseOf<"get", "./patients/:patientId/documents">["documents"][0];

interface Props {
  portalDocuments: Messages["PatientPortalDocument"][];
}

export const instantComparator = (a: PortalDocumentRow, b: PortalDocumentRow) => {
  if (a.createdAt.isBefore(b.createdAt)) {
    return 1;
  } else if (a.createdAt.isAfter(b.createdAt)) {
    return -1;
  }
  return 0;
};

export const formatDocumentType = (type: Messages["PatientPortalDocumentType"]): string => {
  switch (type) {
    case "AUTH_REP":
      return "Authorized Representative Document";
    case "NYIA_REQUEST":
      return "NYIA Request Form";
    case "PRIVATE_PAY":
      return "Private pay agreement";
    case "SUPPLEMENT":
      return "Supplement A Form";
    case "UNDER_18":
      return "Under 18 Documents";
    case "WELCOME_PACKAGE":
      return "Welcome Package";
  }
};

const IntakePatientPortalDocuments = (props: Props) => {
  const orderedPortalDocuments = sortWith(props.portalDocuments, instantComparator);
  return (
    <Flex direction="column" gap={4}>
      <Heading as="h2" size="md">
        Portal Documents
      </Heading>
      {orderedPortalDocuments.length === 0 ? (
        <NoDocumentsContainer />
      ) : (
        <List display="flex" flexDirection="column" gap={4}>
          {orderedPortalDocuments.map((doc) => {
            return (
              <React.Fragment key={`portal-doc-${doc.id}`}>
                <ListItem>
                  <PortalDocumentRow document={doc} />
                </ListItem>
                <Divider variant="dashed"></Divider>
              </React.Fragment>
            );
          })}
        </List>
      )}
    </Flex>
  );
};

interface PortalDocumentRowProps {
  document: PortalDocumentRow;
}

const PortalDocumentRow = (props: PortalDocumentRowProps) => {
  const { createdAt, type, url } = props.document;
  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center" gap={2}>
        <TextDocumentOutlineIcon />
        <Text>{fmap(createdAt, (v) => dateFormatter.toDateTime(v))}</Text>
        <Divider borderColor="black" orientation="vertical" />
        <Text>{formatDocumentType(type)}</Text>
      </Flex>
      <a href={url} rel="noreferrer" target="_blank">
        <Flex alignItems="center" color="blue.600">
          Open
          <ExternalLinkIcon mx="2px" />
        </Flex>
      </a>
    </Flex>
  );
};

const NoDocumentsContainer = () => {
  return (
    <Flex direction="column">
      <Text>No portal documents uploaded.</Text>
    </Flex>
  );
};

export default IntakePatientPortalDocuments;
