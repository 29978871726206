import { DefaultSmsMessageFields, PortalLink, RegularMessage } from "./sms-sender.types";

const patientDefaultSmsMessageTemplates = {
  ScheduleCall: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, hope all is well. This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. I would love to schedule a call to review your home care needs. When would be a good time for me to call you? Thank you!`,
  Portal: {
    HomePage: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link to enter our site: `,
    PatientProfile: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, this is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link to enter the patient profile and fill in the necessary details: `,
    AuthRepDoc: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link and fill out the Authorize Representative Document: `,
    NyiaRequestForm: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link to view the NYIA request form:`,
    NYIA: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link to view your scheduled NYIA assessments.`,
    Under18Forms: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please get one of the forms in the link signed by the patient’s physician 🙏🏼
    Email it back to us to patients@mail.medflyt.com
    Good luck from the Medflyt At Home team 😇 
    Click here to view the files - 
    `,
    IntakeFlow: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link to start your home care process: `,
    PrivatePay: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, 
    This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home.
    I’m sending here the link to our service agreement, once signed we will be able to start finding the best caregiver for you!
    Click the link, go over the details and sign 💃
    If you have more questions, feel free to reach out here by SMS or call us back at (347) 315 - 1770
    Thank you, Medflyt at home 
    Link to the agreement -  `,
    SupplementA: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. Please click on this link to view the Supplement A form: `,
    WelcomePackage: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home. before we start services we need you to sign the service agreement and some forms, it should be really quick but we must have it before we can start the services.
    Link to the form: `,
  },
  BeforeUAS: `HI ${DefaultSmsMessageFields.FIRST_NAME},
  This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home.
  Please provide this information to the Clinician while completing your UAS and make sure the clinician is aware that you want PCA services from our agency. 
  Agency full Name: MJ-MC Home Health Care Agency (Medflyt at Home).
  📞 Phone: 347-315-1770.
  📠 Fax: 917-672-7917.
  📃 NPI: 152-828-6150.
  📄 EIN: (tax ID) 0505-85542.
  📍 Agency Address: 2329 Nostrand Ave Brooklyn NY 11210.
  `,
  SwitchingAgencies: `HI ${DefaultSmsMessageFields.FIRST_NAME},
  This is ${DefaultSmsMessageFields.AGENCY_MEMBER} from Medflyt at Home.
  Please provide this information to your case manager in case they contact you regarding switching to our agency -
  
  Agency full Name: MJ-MC Home Health Care Agency (Medflyt at Home).
  📞 Phone: 347-315-1770.
  📠 Fax: 917-672-7917.
  📃 NPI: 152-828-6150.
  📄 EIN: (tax ID) 0505-85542.
  📍 Agency Address: 2329 Nostrand Ave Brooklyn NY 11210.
  📳 Website: https://www.medflyt.com/en
  `,
};

export const portalLinkMessageTemplateOptions: { [key: string]: PortalLink } = {
  HomePage: {
    label: "[Patient Portal] Send link to Home Page",
    redirectUrl: "/",
    value: patientDefaultSmsMessageTemplates.Portal.HomePage,
  },
  PatientProfile: {
    label: "[Patient Portal] Send link to Patient Profile",
    redirectUrl: "/profile",
    value: patientDefaultSmsMessageTemplates.Portal.PatientProfile,
  },
  AuthRepDoc: {
    label: "[Patient Portal] Send link to Authorized Rep. Document",
    redirectUrl: "/documents/auth_rep",
    value: patientDefaultSmsMessageTemplates.Portal.AuthRepDoc,
  },
  NyiaRequestForm: {
    label: "[Patient Portal] Send link to NYIA Request Form",
    redirectUrl: "/documents/nyia_request",
    value: patientDefaultSmsMessageTemplates.Portal.NyiaRequestForm,
  },
  NYIA: {
    label: "[Patient Portal] Send link to NYIA Page",
    redirectUrl: "/nyia",
    value: patientDefaultSmsMessageTemplates.Portal.NYIA,
  },
  Under18Forms: {
    label: "[Patient Portal] Send link to Under 18 Forms",
    redirectUrl: "/documents/under_18",
    value: patientDefaultSmsMessageTemplates.Portal.Under18Forms,
  },
  IntakeFlow: {
    label: "[Patient Portal] Send link to Intake Flow",
    redirectUrl: "/intake-flow",
    value: patientDefaultSmsMessageTemplates.Portal.IntakeFlow,
  },
  PrivatePay: {
    label: "[Patient Portal] Send link to Private Pay Agreement",
    redirectUrl: "/documents/private_pay",
    value: patientDefaultSmsMessageTemplates.Portal.PrivatePay,
  },
  SupplementA: {
    label: "[Patient Portal] Send link to Supplement A",
    redirectUrl: "/documents/supplement_a",
    value: patientDefaultSmsMessageTemplates.Portal.SupplementA,
  },
  WelcomePackage: {
    label: "[Patient Portal] Send link to Welcome Package",
    redirectUrl: "/documents/welcome_package",
    value: patientDefaultSmsMessageTemplates.Portal.WelcomePackage,
  },
};

export const patientRegularMessageTemplateOptions: { [key: string]: RegularMessage } = {
  ScheduleCall: {
    label: "[Intake] Schedule call",
    value: patientDefaultSmsMessageTemplates.ScheduleCall,
  },
  BeforeUAS: {
    label: "[Intake] Before UAS",
    value: patientDefaultSmsMessageTemplates.BeforeUAS,
  },
  SwitchingAgencies: {
    label: "[Intake] Switching Agencies",
    value: patientDefaultSmsMessageTemplates.SwitchingAgencies,
  },
};

export const smsCommCenterLabelCodesByTeam = {
  Intake: "SMS_CONVERSATION",
  Staffing: "SMS_CONVERSATION_STAFFING",
  HR: "SMS_CONVERSATION_HR",
};

const caregiverDefaultSmsMessageTemplates = {
  OnboardingLink: `Hi ${DefaultSmsMessageFields.FIRST_NAME}, hope all is well. We just talked regarding an application for Medflyt at home 👏
Here is the link to get started on your On-boarding application - all is done online: onboarding.medflyt.com`,
};

export const caregiverRegularMessageTemplateOptions: { [key: string]: RegularMessage } = {
  OnboardingLink: {
    label: "[Onboarding] Send link to onboarding application",
    value: caregiverDefaultSmsMessageTemplates.OnboardingLink,
  },
};
