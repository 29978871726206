import moment from "moment";
import $ from "jquery";
import { getFullName } from "@medflyt/webapp-react/src/shared/utils/get-full-name";
import EditVisitEntityNoteFormModal from "@medflyt/webapp-react/src/modules/note/components/EditVisitEntityNoteFormModal";
import { withAppOnly } from "@medflyt/webapp-react/src/utils";
import { renderReactComponent } from "@medflyt/webapp-react/src/shared/utils/render-react-component";
import { LocalDate, LocalDateTime, convert, nativeJs, Duration } from "@js-joda/core";

//! @ngInject
export function visitInstanceModalCtrl(
    GoogleAddressService,
    $timeout,
    $scope,
    $rootScope,
    SweetAlert,
    mfModal,
    DatabaseApi,
    toaster,
    $uibModal,
    $state,
    PatientModalService,
    VisitInstanceModalService,
    visitInstanceService,
    $uibModalInstance,
    TimeZoneService,
    NgTableParams,
    wildcard,
    $filter,
    entityNoteService,
    noteConsts,
    timesheetsService,
) {
    $scope.visit = {

    };

    $scope.visitHoursRestrictionError = {
        error: false,
        conflicts: []
    }

    $scope.patientId = $scope.$resolve.patientId;

    let visitInstanceId = $scope.$resolve.visitId;
    const visitInstanceIds = $scope.$resolve.visitInstanceIds;

    if (visitInstanceId && visitInstanceIds) {
        throw new Error("Can't open visitInstanceModalCtrl with both visitInstanceId or visitInstanceIds. Please pass only one of them");
    }

    var formatDateToTime = function (date) {
        if (!date) return '';
        return $filter('mfShortTime')(date);
    }

    $scope.generalForm = {};
    $scope.visitInfoForm = {};
    $scope.billingAndPayrollForm = {};
    $scope.visitAuthorizationForm = {};
    $scope.visitAuthorizationLoading = true;
    $scope.isLoadingPOC = false;
    $scope.editAndShowCompletedTasksOnly = true;
    $scope.promisesInProgress = [];
    $scope.agencyMembers = DatabaseApi.getAgencyMembers();
    $scope.dirtyFields = {};

    const ModalMode = {
        Single: "SINGLE",
        BulkEdit: "BULK_EDIT"
    };

    $scope.modalMode = (() => {
        if (visitInstanceId) {
            return ModalMode.Single;
        }

        if (visitInstanceIds && visitInstanceIds.length === 1) {
            visitInstanceId = visitInstanceIds[0];
            return ModalMode.Single;
        }

        if (visitInstanceIds) {
            return ModalMode.BulkEdit
        }

        throw new Error("Failed to determine the modal mode (not in ModalMode)");
    })();

    var calcTotalShiftTime = function (start, end, { shouldAddOneDayToEndDateWhenStartIsAfterEnd }) {
        if (!start || !end) return '';
        var startMoment = start, endMoment = end;
        if (typeof (start) !== 'string') {
            startMoment = moment(start);
        } else {
            startMoment = moment();
            var colonId = start.indexOf(':');
            startMoment.set({
                'hours': parseInt(start.slice(0, start.indexOf(':'))),
                'minutes': parseInt(start.slice(colonId + 1, colonId + 3))
            });
            if (start[start.length - 2] === 'P' && startMoment.get('hours') < 12) {
                startMoment.add(12, 'hours');
            }
        }
        if (typeof (end) !== 'string') {
            endMoment = moment(end);
        } else {
            endMoment = moment();
            var colonId = end.indexOf(':');
            endMoment.set({
                'hours': parseInt(end.slice(0, end.indexOf(':'))),
                'minutes': parseInt(end.slice(colonId + 1, colonId + 3))
            });
            if (end[end.length - 2] === 'P' && endMoment.get('hours') < 12) {
                endMoment.add(12, 'hours');
            }
        }
        if (startMoment.isAfter(endMoment)) {
            if (!shouldAddOneDayToEndDateWhenStartIsAfterEnd) {
                return "Invalid duration";
            }
            endMoment.add(1, 'days');
        }

        var overnight = '';
        if (endMoment.isAfter(startMoment, 'day')) {
            overnight = ' Overnight';
        }
        var duration = moment.duration(endMoment.diff(startMoment));
        var hours = Math.floor(duration.asHours());
        if (hours > 0) duration.subtract(hours, "hours");
        var minutes = Math.floor(duration.asMinutes());
        if (hours > 0 && minutes > 0) return hours + ' Hours, ' + minutes + ' Minutes' + overnight;
        if (hours > 0 && minutes == 0) return hours + ' Hours' + overnight;
        if (hours <= 0 && minutes > 0) return minutes + ' Minutes' + overnight;
        return '0 Minutes';
    }

    $scope.getDurationLabelByMinutes = function (minutes) {
        if (!minutes && minutes !== 0) return '';
        var duration = moment.duration(minutes * 60 * 1000);
        var hours = Math.floor(duration.asHours());
        if (hours > 0) duration.subtract(hours, "hours");
        minutes = Math.floor(duration.asMinutes());
        if (minutes < 10) minutes = '0' + minutes;
        return hours + ':' + minutes + ' Hours';
    }

    $scope.isTotalShiftTimeValid = function (start, end, { shouldAddOneDayToEndDateWhenStartIsAfterEnd }) {
        if (!start || !end) return false;
        var strTotal = calcTotalShiftTime(start, end, { shouldAddOneDayToEndDateWhenStartIsAfterEnd });
        if (strTotal === "Invalid duration") {
            return false;
        }
        strTotal = strTotal.slice(0, strTotal.indexOf(' '));
        return parseInt(strTotal) > 0;
    }

    $scope.clickMissedVisit = function () {
        $scope.isEdit.missedVisit = !$scope.isEdit.missedVisit;
        $scope.checkUpdateForm('visitInfoForm', 'missedVisit');
    }

    $scope.handleKeyPress = function (event) {
        if (event.which === 13) {
            event.target.blur();
        }
    }

    $scope.updateTotalScheduledShiftTime = function () {
        if ($scope.visitInfoForm.startTime) {
            $scope.dirtyFields.startTime = $scope.visitInfoForm.startTime;
        }

        if ($scope.visitInfoForm.endTime) {
            $scope.dirtyFields.endTime = $scope.visitInfoForm.endTime;
        }

        if ($scope.modalMode === ModalMode.BulkEdit) {
            $scope.isEdit.visitInfoFormDirty = true;
        }

        if (!$scope.visitInfoForm.startTime || !$scope.visitInfoForm.endTime) {
            return;
        }

        const startDateTime = LocalDateTime.from(nativeJs($scope.visitInfoForm.startTime));
        const endDateTime = LocalDateTime.from(nativeJs($scope.visitInfoForm.endTime));

        // ------- WEIRD LOGIC STARTS HERE --------- //
        // This is ugly, but I didn't want to change the whole logic.
        // Basically, when the end time is before the start time, then it means it's overnight,
        // which means the end date will occur the next time, which technically mean we add a day to the end time.
        // because we add a day to the end time, we need to make sure to substract one day when have a duration of at least 24 hours.
        // and all of this logic is being done because we preferred not to use datetime for ux reasons.
        // If you feel like you want to rewrite this logic, make sure to take this edge case into your considerations.
        if (endDateTime.toLocalDate().isEqual(startDateTime.toLocalDate())) {
            if (endDateTime.toLocalTime().isBefore(startDateTime.toLocalTime())) {
                $scope.visitInfoForm.endTime = convert(endDateTime.plusDays(1)).toDate();
            }
        }

        if (Duration.between(startDateTime, endDateTime).toMinutes() > 60 * 24) {
            $scope.visitInfoForm.endTime = convert(startDateTime.withHour(endDateTime.hour()).withMinute(endDateTime.minute())).toDate();
        }
        // ------- WEIRD LOGIC ENDS HERE --------- //

        var totalScheduledTime = calcTotalShiftTime($scope.visitInfoForm.startTime, $scope.visitInfoForm.endTime, { shouldAddOneDayToEndDateWhenStartIsAfterEnd: true });
        $scope.visitInfoForm.totalScheduledTime = totalScheduledTime;
        if (JSON.stringify($scope.defaultVisitInfoForm) === JSON.stringify($scope.visitInfoForm)) {
            $scope.isEdit.visitInfoFormDirty = false;
        } else {
            $scope.isEdit.visitInfoFormDirty = true;
        }
    }

    $scope.updateTotalClockShiftTime = function () {
        resetClockInOutSeconds();

        var totalClockTime = calcTotalShiftTime($scope.visitInfoForm.clockinTime, $scope.visitInfoForm.clockoutTime, { shouldAddOneDayToEndDateWhenStartIsAfterEnd: false });
        $scope.visitInfoForm.totalClockTime = totalClockTime;
        if (JSON.stringify($scope.defaultVisitInfoForm) === JSON.stringify($scope.visitInfoForm)) {
            $scope.isEdit.visitInfoFormDirty = false;
        } else {
            $scope.isEdit.visitInfoFormDirty = true;
        }
        if ((moment($scope.defaultVisitInfoForm.clockinTime).isSame($scope.visitInfoForm.clockinTime) &&
             moment($scope.defaultVisitInfoForm.clockoutTime).isSame($scope.visitInfoForm.clockoutTime)) ||
            (($scope.defaultVisitInfoForm.clockinTime === null && $scope.visitInfoForm.clockinTime === null) &&
             moment($scope.defaultVisitInfoForm.clockoutTime).isSame($scope.visitInfoForm.clockoutTime)) ||
            ((moment($scope.defaultVisitInfoForm.clockinTime).isSame($scope.visitInfoForm.clockinTime) &&
             $scope.defaultVisitInfoForm.clockoutTime === null && $scope.visitInfoForm.clockoutTime === null)) ||
            (
                $scope.defaultVisitInfoForm.clockinTime === $scope.visitInfoForm.clockinTime &&
                $scope.defaultVisitInfoForm.clockoutTime === $scope.visitInfoForm.clockoutTime
            )
        ) {
            $scope.isEdit.clocks = false;
            $scope.visitInfoForm.manualClockTimeEditApproved = $scope.visit.visitInfo.manualClockTimeEditApproved;
        } else {
            $scope.isEdit.clocks = true;
            $scope.visitInfoForm.manualClockTimeEditApproved = false;
        }

        $scope.dirtyFields.clockinTime = $scope.visitInfoForm.clockinTime;
        $scope.dirtyFields.clockoutTime = $scope.visitInfoForm.clockoutTime;
    }

    function resetClockInOutSeconds() {
        if ($scope.visitInfoForm.clockinTime) {
            $scope.visitInfoForm.clockinTime.setSeconds(0, 0);
        }

        if ($scope.visitInfoForm.clockoutTime) {
            $scope.visitInfoForm.clockoutTime.setSeconds(0, 0);
        }
    }

    function historySetup() {
        $scope.historyTables = [{ tableName: 'visit_instance', recordId: visitInstanceId }];
        $timeout(function setHistoryObserver() {
            $scope.historyIntersected = 0;
            const observer = new IntersectionObserver((entries, observer) => {
                $scope.historyIntersected++;

                if ($scope.historyIntersected > 1 && document.querySelector("#history-box") !== null) {
                    observer.unobserve(document.querySelector("#history-box"));
                }
                $scope.$digest();
            });

            const historyBox = document.querySelector("#history-box");
            if (historyBox !== null) {
                observer.observe(historyBox);
            }
        }, 500);
    }

    const initData = function () {
        switch ($scope.modalMode) {
            case ModalMode.Single:
                return initSingleModeData();
            case ModalMode.BulkEdit:
                return initBulkEditModeData();
            default:
                throw new Error(`Failed to initData() for the mode "${$scope.modalMode}"`);
        }
    }

    const initBulkUniquePatientOptions = (patientId, visitInstances) => {
        PatientModalService.getPatientContractBasic(patientId, true).then(contracts => {
            $scope.allContractsOptions = contracts.map(contract => ({
                ...contract,
                label: $filter("formatPatientContract")(contract)
            }));

            let patientContractId = "no contract selected";
            let serviceCodeId = "no service code selected";
            let payrollCodeId = "no payroll code selected";

            const patientContractIds = new Set(visitInstances.map(visit => visit.patientContractId));
            if (patientContractIds.size === 1) {
                patientContractId = [...patientContractIds][0];
                $scope.originalContractId = patientContractId;
                $scope.updateServiceCodeOptions(patientContractId);
            }

            const serviceCodeIds = new Set(visitInstances.map(visit => visit.serviceCodeId));
            if (serviceCodeIds.size === 1) {
                serviceCodeId = [...serviceCodeIds][0] || serviceCodeId;
                $scope.originalServiceCodeId = serviceCodeId;

                const payrollCodeIds = new Set(visitInstances.map(visit => visit.payrollCodeId));
                if (payrollCodeIds.size === 1) {
                    payrollCodeId = [...payrollCodeIds][0] || payrollCodeId;
                }
            }

            $scope.billingAndPayrollForm.patientContractId = patientContractId;
            $scope.billingAndPayrollForm.serviceCodeId = serviceCodeId;
            $scope.billingAndPayrollForm.payrollCodeId = payrollCodeId;
            Object.assign($scope.defaultBillingAndPayrollForm, {
                patientContractId: patientContractId,
                serviceCodeId: serviceCodeId,
                payrollCodeId: payrollCodeId,
            });
            $scope.checkUpdateForm('billingAndPayrollForm', 'patientContractId');
            filterContractOptionsByAuthorizations();
        });
    }

    const fetchPatientAuthorizations = (patientId, visitInstances) => {
        if (!$rootScope.showBillingFeature) {
            initBulkUniquePatientOptions(patientId, visitInstances);
        } else {
            const url = "agencies/:agencyId/agency_members/:agencyMemberId/authorizations/:patientId"
                .replace(":agencyId", $rootScope.agencyId)
                .replace(":agencyMemberId", $rootScope.agencyMemberId)
                .replace(":patientId", patientId);
            DatabaseApi.get(url).then((res) => {
                initPatientAuthorizations(res.data.authorizations);
                initBulkUniquePatientOptions(patientId, visitInstances);
            }, (err) => {
                toaster.pop('error', "Something went wrong", "Could not get authorizations");
            });
        }
    };

    const initBulkEditModeData = async function () {
        $scope.bulkVisitInstances = [];
        $scope.bulkHasAtLeastOneCaregiver = false;
        $scope.bulkHasMoreThanOnePatient = false;
        $scope.visitAuthorizationLoading = false;
        $scope.visitAuthorizationForm.authorizations = [];

        $timeout(setCheckboxesAsIndeterminate);

        $scope.isEdit.generalForm = true;
        $scope.isEdit.visitInfoForm = true;
        $scope.isEdit.billingAndPayrollForm = true;

        $scope.isMissedVisitNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.MISSED_VISIT_INSTANCE);
        $scope.isDistanceApprovalNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_DISTANCE_APPROVAL);

        const url = "agencies/:agencyId/agency_members/:agencyMemberId/visits_instances_info"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":visitInstanceId", visitInstanceId)

        const body = { ids: visitInstanceIds };

        DatabaseApi.post(url, body)
            .then(({ data }) => {
                $scope.bulkVisitInstances = data.visitsInstances;
                $scope.bulkHasAtLeastOneCaregiver = data.visitsInstances.find(x => {
                    return x.caregiverId !== null
                }) !== undefined;

                const patients = new Set(data.visitsInstances.map(x => x.patientId));
                $scope.hasMoreThanOnePatient = patients.size > 1;
                $scope.bulkEditContractEditable = true;

                if (!$scope.hasMoreThanOnePatient) {
                    $scope.bulkUniquePatientId = [...patients][0];

                    fetchPatientAuthorizations($scope.bulkUniquePatientId, data.visitsInstances);
                    $scope.bulkEditContractEditable = data.visitsInstances.filter(visit => visit.isBilled).length === 0 ? true : $rootScope.isPermittedByKey("edit_billed_visit_instance_contract_replacement");
                }

                setRelevantCaregivers();
            })
            .catch(console.error);
    }

    $scope.handleClickBulkResetCaregivers = () => {
        $scope.dirtyFields.caregiverId = null;
        $scope.bulkHasAtLeastOneCaregiver = false;
        $scope.isEdit.generalFormDirty = true;
    }

    // IDS OF INPUTS THAT SHOULD BE INDETERMINATE FOR BULK VISIT INSTANCES EDIT MODE
    // FORM KEY MODEL MUST MATCH INPUT ID FOR DIRTY LOGIC TO WORK CORRECTLY
    $scope.bulkIndeterminateInputIds = [
        "manualClockTimeEditApproved",
        "isOnPreviousDay",
        "clockDistanceApproved",
        "missedVisit",
        "manualHoldInvoicing",
    ];
    const setCheckboxesAsIndeterminate = function () {
        try {
            $scope.bulkIndeterminateInputIds.forEach(inputId => {
                $("#" + inputId).get()[0].indeterminate = true;
            });
        } catch {}
    }

    const initSingleModeData = async function () {
        $scope.isLoading = 2;

        const urls = "agencies/:agencyId/agency_members/:agencyMemberId/visit_instance_info/:visitInstanceId"
            .replace(":agencyId", $rootScope.agencyId)
            .replace(":agencyMemberId", $rootScope.agencyMemberId)
            .replace(":visitInstanceId", visitInstanceId);

        $scope.isMissedVisitNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.MISSED_VISIT_INSTANCE);
        $scope.isManualClockTimeNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME);
        $scope.isDistanceApprovalNoteRequired = entityNoteService.isEntityNoteRequired(noteConsts.NoteTypes.VISIT_DISTANCE_APPROVAL);

        DatabaseApi.get(urls).then(function (res) {
            historySetup();
            $scope.originalVisit = res.data;
            $scope.potentialClocks = {
                in: res.data.potentialClockRecords.filter(x => x.callType === "IN"),
                out: res.data.potentialClockRecords.filter(x => x.callType === "OUT"),
            }
            $scope.visit = {};
            Object.assign($scope.visit, res.data);
            setRelevantCaregivers();
            $scope.visit.date = new Date($scope.visit.visitInfo.startTime).toDateString();
            setNotesTableData($scope.visit.visitNotes);
            $scope.addAdjustmentApprovalsData($scope.visit)
            // visit info form
            $scope.visitInfoForm.startTime = new Date($scope.visit.visitInfo.startTime);
            $scope.visitInfoForm.startTime.setSeconds(0, 0);
            $scope.visitInfoForm.endTime = new Date($scope.visit.visitInfo.endTime);
            $scope.visitInfoForm.endTime.setSeconds(0, 0);
            var clockinMoment = undefined;
            var clockoutMoment = undefined;
            if ($scope.visit.clockInTime) {
                clockinMoment = moment($scope.visit.visitInfo.clockIn)
                    .set("seconds", 0)
                    .set("year", $scope.visitInfoForm.startTime.getFullYear())
                    .set("month", $scope.visitInfoForm.startTime.getMonth());
                $scope.visitInfoForm.clockinTime = new Date(clockinMoment);
            }
            if ($scope.visit.visitInfo.clockOut) {
                clockoutMoment = moment($scope.visit.visitInfo.clockOut)
                    .set("seconds", 0)
                    .set("year", $scope.visitInfoForm.startTime.getFullYear())
                    .set("month", $scope.visitInfoForm.startTime.getMonth());

                if (clockinMoment !== undefined && clockoutMoment.isBefore(clockinMoment)) {
                    clockoutMoment.add(1, "days");
                }
                $scope.visitInfoForm.clockoutTime = new Date(clockoutMoment);
            }

            $scope.generalForm = $scope.resetDefaultGeneralForm();
            $scope.visitInfoForm = $scope.resetDefaultVisitInfoForm();
            $scope.updateTotalScheduledShiftTime();
            $scope.updateTotalClockShiftTime();
            $scope.visitInfoForm.missedVisit = $scope.visit.visitInfo.missedVisit;
            $scope.isEdit.missedVisit = $scope.visit.visitInfo.missedVisit;
            $scope.visitInfoForm.isOnPreviousDay = $scope.visit.visitInfo.isVisitDateOnDayBefore;
            $scope.visitInfoForm.manualHoldInvoicing = $scope.visit.visitInfo.manualHoldInvoicing;
            $scope.visitInfoForm.manualHoldInvoicingNotes = $scope.visit.visitInfo.manualHoldInvoicingNotes;
            $scope.visitInfoForm.missingParams = countVisitInfoMissingParams();
            setVisitOfficeName();

            $scope.visit.times = {
                startTime: formatDateToTime($scope.visitInfoForm.startTime),
                endTime: formatDateToTime($scope.visitInfoForm.endTime),
                clockinTime: formatDateToTime($scope.visitInfoForm.clockinTime),
                clockinDate: new Date ($scope.visitInfoForm.clockinTime).toDateString(),
                clockoutTime: formatDateToTime($scope.visitInfoForm.clockoutTime),
                clockoutDate: new Date ($scope.visitInfoForm.clockoutTime).toDateString(),
                totalScheduledTime: $scope.visitInfoForm.totalScheduledTime,
                totalClockTime: $scope.visitInfoForm.totalClockTime
            };

            $scope.isLoading--;
            if ($rootScope.showBillingFeature) {
                DatabaseApi.get(
                    'agencies/' + $rootScope.agencyId +
                    '/agency_members/' + $rootScope.agencyMemberId +
                    '/visit_instances/' + visitInstanceId +
                    '/billing_and_payroll'
                ).then(function (res) {
                    Object.assign($scope.visit, res.data);
                    $scope.visit.issues = ($scope.visit.issues !== null && $scope.visit.issues !== undefined) ? Object.values($scope.visit.issues) : null;
                    $scope.billingAndPayrollForm = $scope.resetDefaultBillingAndPayrollForm();
                    $scope.visitAuthorizationLoading = false;
                    $scope.visitAuthorizationForm.authorizations = [];
                    $scope.visit.matchingAuthorizations.forEach(function (auth) {
                        $scope.visitAuthorizationForm.authorizations.push({
                            hoursAllocated: auth.minutesAllocated / 60
                        });
                    });
                    $scope.isLoading--;
                    $scope.originalServiceCodeId = $scope.billingAndPayrollForm.serviceCodeId;
                    $scope.originalContractId = $scope.billingAndPayrollForm.patientContractId;
                    $scope.updateContractOptions($scope.visit.patient.id);
                    $scope.visit.isBilled = $scope.visit.invoiceId !== null;
                    $scope.visit.isPaid = $scope.visit.payrollBatchId !== null;
                    $scope.visit.isOnPayrollDraft = $scope.visit.isOnPayrollDraft === true;

                    initPatientAuthorizations(res.data.allPatientAuthorizations);
                }, function (err) {
                    console.error(err);
                    toaster.pop('error', 'Something went wrong');
                })
            }
            else {
                $scope.isLoading--;
            }

            const patientFullName = getFullName($scope.visit.patient);

            /**
             * @type {import("@medflyt/webapp-react/src/shared/hooks/useGlobalWorkflowRunner").GlobalWorkflowHint[]}
             **/
            const hints = [
                {
                    type: "prefill-field",
                    fieldType: { type: "entity", entity: "Visit Instance" },
                    display: `Visit Instance #${$scope.visit.visitInfo.visitInstanceId} (${patientFullName})`,
                    value: $scope.visit.visitInfo.visitInstanceId,
                },
                {
                    type: "prefill-field",
                    fieldType: { type: "entity", entity: "Patient" },
                    display: patientFullName,
                    value: $scope.visit.patient.id,
                }
            ];

            if ($scope.visit.caregiver) {
                hints.push({
                    type: "prefill-field",
                    fieldType: { type: "entity", entity: "Caregiver" },
                    display: getFullName($scope.visit.caregiver),
                    value: $scope.visit.caregiver.id,
                })
            }

            $rootScope.react.workflows.setHints(hints);
        }, function (err) {
            console.error(err);
            toaster.pop('error', 'Something went wrong');
        });
    }

    const setVisitOfficeName = () => {
        $scope.visit.officeName = DatabaseApi.offices().find(office => office.id === $scope.visit.visitInfo.officeId)?.name;
    };

    $scope.navigationItems = {
        active: 'scroll-modal-general',
        list: [
            {
                id: 'scroll-modal-general',
                title: 'General'
            },
            {
                id: 'scroll-modal-visit-info',
                title: 'Visit Info'
            },
            {
                id: 'scroll-modal-billing-and-payroll',
                title: 'Billing & Payroll',
                billingFeature: true
            },
            {
                id: 'scroll-modal-authorization',
                title: 'Authorization',
                billingFeature: true
            },
            {
                id: 'scroll-adjustment-approval',
                title: 'Adjustment Approvals',
                billingFeature:true
            },
            {
                id: 'scroll-modal-caregiver-tasks',
                title: 'Caregiver Tasks'
            },
            {
                id: 'scroll-recent-activity',
                title: 'Recent Activity',
                billingFeature: true
            },
            {
                id: 'scroll-visit-notes',
                title: 'Notes'
            }

        ]
    };

    $scope.deleteVisit = function () {
        let visitInstanceIds;
        let requestNoteText = "";
        switch ($scope.modalMode) {
            case ModalMode.Single:
                visitInstanceIds = [$scope.visit.visitInfo.visitInstanceId];
                if ($scope.visit.isBilled || $scope.visit.isPaid || $scope.visit.isOnPayrollDraft) {
                    requestNoteText = `The visit instance is billed, paid or on payroll draft. Please post a note in order to confirm delete.`;
                }
                break;
            case ModalMode.BulkEdit:
                visitInstanceIds = $scope.bulkVisitInstances.map(x => x.visitInstanceId);
                if ($scope.bulkVisitInstances.find(visitInstance => visitInstance.isBilled || visitInstance.isPaid || visitInstance.isOnPayrollDraft)) {
                    requestNoteText = `Some of the selected visit instances are billed, paid or on payroll draft. Please post a note in order to confirm delete.`;
                }
                break;
            default:
                throw new Error(`Failed to $scope.deleteVisit() for the mode "${$scope.modalMode}"`);
        }
        const modalInstance = $uibModal.open({
            templateUrl: "admin/views/approve-visit-instance-delete-modal.html",
            size: "md",
            controller: "approveVisitInstanceDeleteModalCtrl",
            resolve: {
                visitInstanceIds: () => visitInstanceIds,
                requestNoteText: () => requestNoteText,
            }
        });

        modalInstance.result.then(function (res) {
            if (res.success === true) {
                if ($scope.modalMode === ModalMode.Single) {
                    $scope.visit.visitInfo.removedAt = new Date();
                    $scope.visit.visitInfo.manualRemovedVisitNote = res.manualRemovedVisitNote;
                }
                $rootScope.$broadcast('refresh_visits');
            }
        });
    }

    $scope.removeVisit = function () {
        let visitInstanceIds;
        let requestNoteText = "";
        switch ($scope.modalMode) {
            case ModalMode.Single:
                visitInstanceIds = [$scope.visit.visitInfo.visitInstanceId];
                if ($scope.visit.isBilled || $scope.visit.isPaid || $scope.visit.isOnPayrollDraft) {
                    requestNoteText = `The visit instance is billed, paid or on payroll draft. Please post a note in order to confirm delete.`;
                }
                break;
            case ModalMode.BulkEdit:
                visitInstanceIds = $scope.bulkVisitInstances.map(x => x.visitInstanceId);
                if ($scope.bulkVisitInstances.find(visitInstance => visitInstance.isBilled || visitInstance.isPaid || visitInstance.isOnPayrollDraft)) {
                    requestNoteText = `Some of the selected visit instances are billed, paid or on payroll draft. Please post a note in order to confirm delete.`;
                }
                break;
            default:
                throw new Error(`Failed to $scope.deleteVisit() for the mode "${$scope.modalMode}"`);
        }
        const modalInstance = $uibModal.open({
            templateUrl: "admin/views/approve-visit-instance-remove-modal.html",
            size: "md",
            controller: "approveVisitInstanceRemoveModalCtrl",
            resolve: {
                visitInstanceIds: () => visitInstanceIds,
                requestNoteText: () => requestNoteText,
            }
        });

        modalInstance.result.then(function (res) {
            if (res.success === true) {
                if ($scope.modalMode === ModalMode.Single) {
                    $scope.visit.visitInfo.manualRemovedVisitNote = res.manualRemovedVisitNote;
                    res.isRemoveNoteAnswer ?
                        $scope.visit.visitInfo.removedAt = new Date() :
                        $scope.visit.visitInfo.missedVisit = true;
                }
                $rootScope.$broadcast('refresh_visits');
            }
        });
    }

    $scope.toggleEditable = (editKey) => {
        // Disable editing for Billed or Paid visit instances
        if ($rootScope.showBillingFeature && $scope.visit.isBilled && !$rootScope.isPermittedByKey('edit_billed_visit_instance_info')) {
            return toaster.pop("warning", "Cannot edit visit instance", "Billed visits cannot be edited");
        }
        if ($rootScope.showBillingFeature && $scope.visit.isPaid && !$rootScope.isPermittedByKey('edit_paid_visit_instance_info')) {
            return toaster.pop("warning", "Cannot edit visit instance", "Paid visits cannot be edited");
        }
        if ($rootScope.showBillingFeature && $scope.visit.isOnPayrollDraft && !$rootScope.isPermittedByKey('edit_visit_instance_on_payroll_draft')) {
            return toaster.pop("warning", "Cannot edit visit instance", "Visits on payroll draft cannot be edited");
        }

        $scope.isEdit[editKey] = !$scope.isEdit[editKey];
    }

    $scope.goToItem = function (item) {
        if (!item) {
            return;
        }
        $scope.navigationItems.active = item.id;

        $('#visit-instance-content-container').animate({
            scrollTop: document.getElementById(item.id).offsetTop
        }, 500);
    };

    $scope.onlyUniqueCertifications = (arr) => {
        return arr.map(item => item.certification).filter((value, index, self) => self.indexOf(value) === index);
    };

    $scope.updateContractOptions = function (patientId) {
        PatientModalService.getPatientContractBasic(patientId, true).then(contracts => {
            $scope.allContractsOptions = contracts.map(contract => ({
                ...contract,
                label: $filter("formatPatientContract")(contract)
            }));
            filterContractOptionsByAuthorizations();
        });
    }

    $scope.updateServiceCodeOptions = function (patientContractId) {
        if (!$scope.contractsOptions) {
            return;
        }

        const contractOption = $scope.contractsOptions.find(contract => contract.id === patientContractId);
        if (!contractOption) {
            $scope.serviceCodeArr = [];
            $scope.billingAndPayrollForm.serviceCodeId = "no service code selected";
            $scope.updatePayrollCodeOptions();
            $scope.checkUpdateForm('billingAndPayrollForm', 'serviceCodeId');
            return;
        }

        const contractTypeId = contractOption.contractTypeId;
        let startTime;

        if ($scope.isBulkMode()) {
            startTime = $scope.bulkVisitInstances.sort((a, b) => {
                const AStart = moment(a.startTime);
                const BStart = moment(b.startTime);
                return AStart.isBefore(BStart) ? 1 : -1;
            })[0].startTime;
        } else {
            startTime = $scope.visit.visitInfo.startTime;
        }

        const visitStartTime = moment(startTime);
        DatabaseApi.getBillingRates(contractTypeId).then(
            function (data) {
                $scope.billingRates = data.data.billingRates.filter(function (curr) {
                    return (
                        visitStartTime.isAfter(curr.startDate) &&
                        visitStartTime.isBefore(curr.endDate)
                    );
                });

                $scope.serviceCodeArr = DatabaseApi.activeServiceCodes().filter(function (currServiceCode
                ) {
                    return $scope.billingRates.some(function (currBillingRate) {
                        return currBillingRate.serviceCode === currServiceCode.id;
                    });
                });

                $scope.serviceCodeArr.forEach(scOption => {
                    scOption.serviceCodeName = angular.copy(scOption.label);
                    if (scOption.id === $scope.originalServiceCodeId) {
                        scOption.label = `${scOption.code} ${scOption.certification} (current)`;
                    } else {
                        scOption.label = `${scOption.code} ${scOption.certification}`;
                    }
                });
                if (
                    $scope.authorizations !== undefined &&
                    $scope.authorizations.isFiltering &&
                    $scope.authorizations.contractIds.includes($scope.billingAndPayrollForm.patientContractId)
                ) {
                    $scope.serviceCodeArr = $scope.serviceCodeArr.filter(scOption =>
                        $scope.authorizations.contractServiceCodesMap[$scope.billingAndPayrollForm.patientContractId + '']
                            .includes(scOption.id)
                        || scOption.id === $scope.originalServiceCodeId
                    );
                }

                if ($scope.generalForm.caregiver && typeof $scope.generalForm.caregiver.certification === "string") {
                    $scope.serviceCodeArr = $scope.serviceCodeArr.filter(item => item.certification === $scope.generalForm.caregiver.certification);
                }

                $scope.uniqueCertifications = $scope.onlyUniqueCertifications(
                    $scope.serviceCodeArr
                );

                if ($scope.uniqueCertifications.length === 1) {
                    $scope.certification = $scope.uniqueCertifications[0];
                }

                if ($scope.serviceCodeArr.length == 0) {
                    $scope.billingAndPayrollForm.serviceCodeId = "no service code selected";
                } else if ($scope.serviceCodeArr.length === 1) {
                    $scope.billingAndPayrollForm.serviceCodeId = $scope.serviceCodeArr[0].id;
                }
                $scope.updatePayrollCodeOptions();
                $scope.checkUpdateForm('billingAndPayrollForm', 'serviceCodeId');
            }
        );
    }

    $scope.updatePayrollCodeOptions = function (isCausedByChange) {
        if ($scope.billingAndPayrollForm.serviceCodeId !== 'no service code selected') {
            const currServiceCode = $scope.serviceCodeArr.find(function (sc) {
                return sc.id == $scope.billingAndPayrollForm.serviceCodeId;
            });

            if (currServiceCode) {
                $scope.payrollCodeArr = $scope.activePayrollCodes.filter(function (pc) {
                    return currServiceCode.payrollCodes.some(function (currPayrollId) {
                        return pc.id === currPayrollId;
                    });
                });
            } else {
                $scope.payrollCodeArr = [];
            }

            if (isCausedByChange) {
                $scope.billingAndPayrollForm.payrollCodeId = 'no payroll code selected';
            }
        } else {
            $scope.billingAndPayrollForm.payrollCodeId = 'no payroll code selected';
            $scope.payrollCodeArr = [];
        }

        if (!isCausedByChange) {
            $scope.updatePayrollCodeCode();
        }
    }

    $scope.updatePayrollCodeCode = function () {
        var foundPayrollCode = DatabaseApi.payrollCodes().find((pc) => pc.id === $scope.visit.payrollCodeId);
        if (foundPayrollCode) {
            $scope.visit.payrollCodeCode = foundPayrollCode.displayId;
        }
    }

    $scope.allServiceCodes = DatabaseApi.activeServiceCodes();
    $scope.activePayrollCodes = DatabaseApi.activePayrollCodes();

    $rootScope.$on("got_payroll_codes", () => {
        $scope.activePayrollCodes = DatabaseApi.activePayrollCodes();
        $scope.updatePayrollCodeOptions();
    });

    $rootScope.$on("got_offices", () => {
        if ($scope.visit.visitInfo) {
            setVisitOfficeName();
        }
    });

    $scope.isEdit = {
        generalForm: false,
        generalFormDirty: false,
        visitInfoForm: false,
        visitInfoFormDirty: false,
        billingAndPayrollForm: false,
        billingAndPayrollFormDirty: false,
        authorization: false,
        authorizationDirty: false
    };

    $scope.canEditClockinTimes = function () {
        switch ($scope.modalMode) {
            case "BULK_EDIT":
                return false;
            case "SINGLE":
                return canEditClockTimeSingleEdit({
                    dateTime: $scope.visit?.visitInfo?.startTime,
                    caregiverId: $scope.visit?.visitInfo?.caregiverId
                });
            default:
                return false;
        }
    }

    $scope.canEditClockoutTimes = function () {
        switch ($scope.modalMode) {
            case "BULK_EDIT":
                return false;
            case "SINGLE":
                return canEditClockTimeSingleEdit({
                    dateTime: $scope.visit?.visitInfo?.endTime,
                    caregiverId: $scope.visit?.visitInfo?.caregiverId
                });
            default:
                return false;
        }
    }

    function canEditClockTimeSingleEdit({
        dateTime,
        caregiverId
    }) {
        return ![undefined, null].includes(dateTime)
            && LocalDateTime.from(nativeJs(new Date(dateTime))).isBefore(LocalDateTime.now())
            && ![undefined, null].includes(caregiverId);
    }

    $scope.toggleEditSection = function (section) {
        if (!section) {
            return;
        }
        $scope[section] = !$scope[section];
    }

    var countGeneralMissingParams = function () {
        var counter = 0;
        if ($scope.visit.visitInfo && !$scope.visit.visitInfo.address) counter++;
        return counter;
    }

    var countVisitInfoMissingParams = function () {
        var counter = 0;
        if (!$scope.visit.startTime && $scope.modalMode !== ModalMode.BulkEdit) counter++;
        if (!$scope.visit.endTime && $scope.modalMode !== ModalMode.BulkEdit) counter++;
        if ($scope.visit.visitInfo && ($scope.visit.visitInfo.missedVisit === undefined || $scope.visit.visitInfo.missedVisit === null)) counter++;
        return counter;
    }

    var countBillingAndPayrollMissingParams = function () {
        var counter = 0;
        if (!$scope.visit.serviceCodeId) counter++;
        return counter;
    }

    var getMissingParams = function (formName) {
        var counter = 0;

        switch (formName) {
            case ('generalForm'):
                break;
            case ('visitInfoForm'):
                if (!$scope.visitInfoForm.startTime) counter++;
                if (!$scope.visitInfoForm.endTime) counter++;
                if ($scope.visitInfoForm.missedVisit === undefined || $scope.visitInfoForm.missedVisit === null) counter++;
                if (
                    $scope.isEdit.clocks
                ) {
                    const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService
                        .validateEntityNote($scope.visitInfoForm.manualClockNote, noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME);
                    if (!isNoteValid) {
                        $scope.manualClockTimeNoteValidations = { isPredefinedValid, isMessageValid };
                        counter++;
                    }
                }
                if (
                    $scope.isEdit.clockDistanceApproved
                ) {
                    const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService
                        .validateEntityNote($scope.visitInfoForm.distanceApprovalNote, noteConsts.NoteTypes.VISIT_DISTANCE_APPROVAL);
                    if (!isNoteValid) {
                        $scope.distanceApprovalNoteValidations = { isPredefinedValid, isMessageValid };
                        counter++;
                    }
                }
                if (
                    $scope.isEdit.missedVisit
                ) {
                    const { isNoteValid, isPredefinedValid, isMessageValid } = entityNoteService
                        .validateEntityNote($scope.visitInfoForm.missedVisitNote, noteConsts.NoteTypes.MISSED_VISIT_INSTANCE);
                    if (!isNoteValid) {
                        $scope.missedVisitNoteValidations = { isPredefinedValid, isMessageValid };
                        counter++;
                    }
                }
                break;
            case ('billingAndPayrollForm'):
                if (!$scope.billingAndPayrollForm.serviceCodeId || $scope.billingAndPayrollForm.payrollCodeId === "no service code selected") counter++;
                break;
        }

        return counter;
    }

    $scope.resetDefaultGeneralForm = function () {
        const tempForm = {
            caregiver: $scope.visit.caregiver || null,
            address: ($scope.visit.visitInfo !== undefined && $scope.visit.visitInfo.address !== undefined) ? $scope.visit.visitInfo.address : null,
            address2: $scope.visit.visitInfo !== undefined ? $scope.visit.visitInfo.address2 : null,
            addressInstructions:$scope.visit.visitInfo !== undefined ? $scope.visit.visitInfo.addressInstructions : null,
            missingParams: countGeneralMissingParams(),
            agreeChanges: false
        };

        $scope.defaultGeneralForm = angular.copy(tempForm);
        return Object.assign({}, tempForm);
    }

    $scope.resetDefaultVisitInfoForm = function () {
        $scope.visit.startTime = $scope.visit.visitInfo && $scope.visit.visitInfo.startTime ? new Date(new Date($scope.visit.visitInfo.startTime).setSeconds(0, 0)) : null;
        $scope.visit.endTime = $scope.visit.visitInfo && $scope.visit.visitInfo.endTime ? new Date(new Date($scope.visit.visitInfo.endTime).setSeconds(0, 0)) : null;
        $scope.visit.clockInTime = $scope.visit.visitInfo && $scope.visit.visitInfo.clockIn ? new Date(new Date($scope.visit.visitInfo.clockIn).setSeconds(0, 0)) : null;
        $scope.visit.clockOutTime = $scope.visit.visitInfo && $scope.visit.visitInfo.clockOut ? new Date(new Date($scope.visit.visitInfo.clockOut).setSeconds(0, 0)) : null;
        $scope.visit.manualClockNote = null;
        $scope.visit.distanceApprovalNote = null;
        $scope.visit.missedVisitNote = null;
        $scope.isEdit.clockDistanceApproved = false;

        const tempForm = {
            startTime: $scope.visit.startTime || null,
            endTime: $scope.visit.endTime || null,
            clockinTime: $scope.visit.clockInTime || null,
            clockoutTime: $scope.visit.clockOutTime || null,
            totalScheduledTime: calcTotalShiftTime($scope.visit.startTime, $scope.visit.endTime, { shouldAddOneDayToEndDateWhenStartIsAfterEnd: true }),
            totalClockTime: calcTotalShiftTime($scope.visit.clockInTime, $scope.visit.clockOutTime, { shouldAddOneDayToEndDateWhenStartIsAfterEnd: false }),
            missedVisit: $scope.visit.visitInfo && ($scope.visit.visitInfo.missedVisit === true || $scope.visit.visitInfo.missedVisit === false) ?
                ($scope.visit.visitInfo.missedVisit || false) : false,
            isOnPreviousDay: ($scope.visit.visitInfo && $scope.visit.visitInfo.isVisitDateOnDayBefore) ? $scope.visit.visitInfo.isVisitDateOnDayBefore
                : false,
            travelTimeMinutes: ($scope.visit.visitInfo && $scope.visit.visitInfo.travelTimeSeconds) ? $scope.visit.visitInfo.travelTimeSeconds / 60 : null,
            missingParams: countVisitInfoMissingParams(),
            agreeChanges: false,
            manualClockTimeEditApproved: ($scope.visit.visitInfo && $scope.visit.visitInfo.manualClockTimeEditApproved) ? $scope.visit.visitInfo.manualClockTimeEditApproved : false,
            manualClockNote: null,
            clockDistanceApproved: ($scope.visit.visitInfo && $scope.visit.visitInfo.clockDistanceApproved) ? $scope.visit.visitInfo.clockDistanceApproved : false,
            distanceApprovalNote: null,
            missedVisitNote: null,
            manualHoldInvoicing: ($scope.visit.visitInfo && $scope.visit.visitInfo.manualHoldInvoicing) ? $scope.visit.visitInfo.manualHoldInvoicing
                : false,
            manualHoldInvoicingNotes: ($scope.visit.visitInfo && $scope.visit.visitInfo.manualHoldInvoicingNotes) ? $scope.visit.visitInfo.manualHoldInvoicingNotes
                : null,
            instapayRulesExemption: ($scope.visit.visitInfo && $scope.visit.visitInfo.instapayRulesExemption) ? $scope.visit.visitInfo.instapayRulesExemption : null,
            patientConfirmedSchedule: $scope.visit.visitInfo?.patientConfirmedSchedule ?? null,
        };

        $scope.defaultVisitInfoForm = angular.copy(tempForm);
        return Object.assign({}, tempForm);
    };

    $scope.resetDefaultBillingAndPayrollForm = function () {
        const tempForm = {
            patientContractId: $scope.visit.patientContractId || 'no contract selected',
            serviceCodeId: $scope.visit.serviceCodeId || 'no service code selected',
            payrollCodeId: $scope.visit.payrollCodeId || 'no payroll code selected',
            missingParams: countBillingAndPayrollMissingParams(),
            billingAdjustmentMinutes: ($scope.visit && $scope.visit.billingAdjustmentSeconds) ? $scope.visit.billingAdjustmentSeconds / 60 : null,
            payrollAdjustmentMinutes: ($scope.visit && $scope.visit.payrollAdjustmentSeconds) ? $scope.visit.payrollAdjustmentSeconds / 60 : null,
            agreeChanges: false
        };

        $scope.defaultBillingAndPayrollForm = angular.copy(tempForm);
        return Object.assign({}, tempForm);

    }

    $scope.generalForm = $scope.resetDefaultGeneralForm();
    $scope.visitInfoForm = $scope.resetDefaultVisitInfoForm();
    $scope.billingAndPayrollForm = $scope.resetDefaultBillingAndPayrollForm();
    $scope.caregiverFilter = { val: '' };

    const filterObject = (obj, predicate) => {
        let result = {}, key;

        for (key in obj) {
            if (obj.hasOwnProperty(key) && !!predicate(obj[key])) {
                result[key] = obj[key];
            }
        }

        return result;
    };

    $scope.handleSeeAllSuggestions = () => {
        $uibModal.open({
            templateUrl: "admin/views/new-visit/new-visit-sugggested-caregivers-modal.html",
            controller: 'newVisitSuggestedCaregiversModalCtrl',
            size: "lg",
            resolve: {
                caregivers: () => $scope.relevantVisitCaregivers,
                handleCaregiverSelect: () => (selectedCaregiver) => $scope.setCaregiver(selectedCaregiver)
            }
        });
    };

    // HHA and PCA caregivers should be selected for both PCA and HHA visits.
    const mapCaregiverCertifications = (certifications) => {
        const hasHHA = certifications.some(cert => cert === "HHA");
        const hasPCA = certifications.some(cert => cert === "PCA");

        if(hasHHA && !hasPCA){
            certifications.push("PCA");
        }

        if(!hasHHA && hasPCA){
            certifications.push("HHA");
        }

        return certifications;
    }

    const setRelevantCaregivers = () => {
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
        $scope.relevantVisitCaregivers = [];
        let filteredCaregivers = [];

        if (!$scope.isBulkMode()) {
            const patientOfficeId = $scope.visit.patient.currentOfficeId;
            if ($scope.visit && $scope.visit.visitInfo && Array.isArray($scope.visit.visitInfo.certifications)) {
                if ($scope.visit.caregiver) {
                    $scope.visit.caregiver.displayName = `${$scope.visit.caregiver.firstName} ${$scope.visit.caregiver.lastName}`;
                }
                const visitCertifications = $scope.visit.visitInfo.certifications;
                filteredCaregivers = filterObject(
                    $scope.caregiversMap,
                    caregiver => (
                        mapCaregiverCertifications(caregiver.certifications).some(certification => visitCertifications.includes(certification)) &&
                        caregiver.status === 'ACTIVE' &&
                        !(caregiver.blacklisted || []).includes(Number($scope.visit.patient.id)) &&
                        caregiver.officeIds.includes(patientOfficeId)
                    )
                )
            }
        }
        $scope.relevantVisitCaregivers = Object.values(filteredCaregivers);
    };

    $rootScope.$on('caregiver_changed', (event) => {
        setRelevantCaregivers();
    });

    $scope.setCaregiver = function (caregiver) {
        $scope.caregiverFilter.val = '';
        $scope.generalForm.caregiver = caregiver;
        if (!$scope.defaultGeneralForm.caregiver || !$scope.defaultGeneralForm.caregiver.id || $scope.defaultGeneralForm.caregiver.id !== $scope.generalForm.caregiver.id) {
            $scope.isEdit.generalForm = true;
            $scope.isEdit.generalFormDirty = true;
        } else {
            $scope.isEdit.generalFormDirty = false;
        }

        if ($scope.generalForm.caregiver.id) {
            $scope.dirtyFields.caregiverId = $scope.generalForm.caregiver.id
        }
    }

    $scope.clearRn = function () {
        const disallowdMethods = ["MOBILE", "IVR"];
        if (
            ($scope.visit.isPaid || $scope.visit.isBilled || $scope.visit.isOnPayrollDraft) ||
            $scope.visit.visitInfo.clockIn !== null && disallowdMethods.includes($scope.visit.visitInfo.clockInMethod) ||
            $scope.visit.visitInfo.clockOut !== null && disallowdMethods.includes($scope.visit.visitInfo.clockOutMethod)) {

            SweetAlert.swal({
                title: "Caregiver Replacement",
                text: "Can't replace caregiver if visit is billed/paid/on payroll draft or clocked via Mobile/IVR",
                type: "error",
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "OK",
                hideCancelButton: true,
                closeOnConfirm: true
            });
            return;
        }

        if ($rootScope.isOnboardingAgency && $scope.generalForm.caregiver) {
            const modalInstance = $uibModal.open({
                templateUrl: "admin/views/approve-visit-instance-unassign-modal.html",
                size: "md",
                controller: "approveVisitInstanceUnassignModalCtrl",
                resolve: {
                    visitInstanceId: () => $scope.visit.visitInstanceId,
                    requestNoteText: () => null,
                    caregiverId: () => $scope.generalForm.caregiver.id,
                    patientId: () => $scope.visit.patient.id
                }
            });

            modalInstance.result.then(function (res) {
                if (res.notePredefinedAnswerId !== null) {
                    $scope.caregiverFilter.val = '';
                    $scope.generalForm.caregiver = null;
                    $scope.generalForm.caregiverUnassignedNote = res;
                    if ($scope.defaultGeneralForm.caregiver && $scope.defaultGeneralForm.caregiver.id) {
                        $scope.isEdit.generalFormDirty = true;
                        $scope.isEdit.generalForm = true;
                    } else {
                        $scope.isEdit.generalFormDirty = false;
                    }

                    $scope.updateServiceCodeOptions($scope.visit.visitInfo.patientContractId);
                }
            });
        }
        else {
            $scope.caregiverFilter.val = '';
            $scope.generalForm.caregiver = null;
            if ($scope.defaultGeneralForm.caregiver && $scope.defaultGeneralForm.caregiver.id) {
                $scope.isEdit.generalFormDirty = true;
                $scope.isEdit.generalForm = true;
            } else {
                $scope.isEdit.generalFormDirty = false;
            }

            $scope.updateServiceCodeOptions($scope.visit.visitInfo.patientContractId);
        }
    }

    $scope.onVisitInstanceAddressChange = async (newAddress) => {
        if (newAddress && newAddress.address_components) {
            try {
                const address = await GoogleAddressService.getAddressComponentsFromMedflytGeoCode(newAddress);
                $scope.generalForm.address = {
                    address: address.formatedAddressDetails.fullAddress,
                    state: address.formatedAddressDetails.state,
                    countyName: address.formatedAddressDetails.county,
                    addressComponents: address
                }

                const timezoneResponse = await TimeZoneService.getTimzone(address.formatedAddressDetails.location);
                if (timezoneResponse) {
                    $scope.generalForm.address.timezone = timezoneResponse.timeZoneId;
                    $scope.checkUpdateForm('generalForm', "address")
                } else {
                    toaster.pop("cant get timezone from location");
                }
            }
            catch (e) {
                toaster.pop('error', 'Invalid address: ' + e.message);
            }

            $scope.dirtyFields.address = $scope.generalForm.address
        }
    }

    const convertPlansOfCare = (plans) => {
        const result = [];
        plans.forEach(function (plan) {
            plan.pocItemWithAnswerList = [];
            let currentTitle = '';
            plan.docItems.forEach(function (docItem) {
                if (docItem.itemType === "bigHeader") currentTitle = docItem.label;
                else {
                    docItem.section = currentTitle;
                    if (docItem.itemType === "check") docItem.type = "Task";
                    else if (docItem.itemType === "smallHeader") docItem.type = "Attribute";
                    else if (docItem.itemType === "yesNo") return;
                    plan.pocItemWithAnswerList.push(docItem);
                }
            });
            result.push(plan);
        });
        return result
    }

    const getPlanOfCareByContracts = (contracts) => {
        let contract = false, treatment = false, plan = undefined;

        if (!contracts.length) {
            toaster.pop('warning', 'No Plan of Care');
            return;
        }

        contracts.forEach(function (cont, i) {
            if (!contract) {
                contract = cont;
                contract.idx = i;
            } else if (cont.id > contract.id) {
                contract = cont;
                contract.idx = i;
            }
        });

        contracts[contract.idx].treatments.forEach(function (treat, i) {

            if (!treatment) {
                treatment = treat;
                treatment.idx = i;
            } else if (treat.id > treatment.id) {
                treatment = treat;
                treatment.idx = i;
            }

        });

        if (!treatment) {
            toaster.pop('warning', 'No Plan of Care');
            return;
        }

        const plansOfCare = convertPlansOfCare(contracts[contract.idx].treatments[treatment.idx].plansOfCare);
        plansOfCare.forEach(function (p, i) {

            if (!plan) {
                plan = p;
            } else if (p.id > plan.id) {
                plan = p;
            }

        });

        return plan;
    }

    $scope.viewPlanOfCare = async function () {
        switch ($scope.modalMode) {
            case ModalMode.Single:
                return $scope.viewPlanOfCareOfSingleMode();
            case ModalMode.BulkEdit:
                return $scope.viewPlanOfCareOfBulkMode();
            default:
                throw new Error(`Failed to viewPlanOfCare() for the mode "${$scope.modalMode}"`);
        }
    }

    $scope.viewPlanOfCareOfBulkMode = async function () {
        toaster.pop("info", "Can't view Plan of Care in bulk mode");
    }

    $scope.viewPlanOfCareOfSingleMode = async function () {
        if ($scope.visit === undefined && $scope.visit.patient === undefined) {
            return;
        }

        $uibModal.open({
            templateUrl: 'admin/views/patient-poc-modal.html',
            size: 'lg',
            controller: 'patientPocModalCtrl',
            windowClass: "modal",
            resolve: {
                patientId: () => $scope.visit.patient.id,
                patientFullName: () => [$scope.visit.patient.firstName, $scope.visit.patient.lastName].join(' '),
                selectedPlanOfCareId: () => $scope.visit.planOfCareVersionDuties.planOfCareId
            }
        });
    }

    $scope.toggleCaregiverDutyCompleted = (dutyItem) => {
        // Allow edit only on show all
        if ($scope.editAndShowCompletedTasksOnly) {
            return;
        }
        const visitInstanceId = $scope.visit.visitInfo.visitInstanceId;

        dutyItem.completed = !dutyItem.completed;

        VisitInstanceModalService.updateVisitCaregiverDuty(
            visitInstanceId,
            dutyItem.planOfCareItemId,
            undefined,
            dutyItem.completed
        )
            .then(() => {
                dutyItem.date = LocalDate.now().toString();
                dutyItem.type = "COORDINATOR";
                toaster.pop("success", "Successfully updated duty");
            })
            .catch(() => {
                toaster.pop("error", "Something went wrong");
            });
    };

    $scope.dutyNotesChanged = (dutyItem) => {
        // Allow edit only on show all
        if ($scope.editAndShowCompletedTasksOnly) {
            return;
        }

        const visitInstanceId = $scope.visit.visitInfo.visitInstanceId;

        VisitInstanceModalService.updateVisitCaregiverDuty(
            visitInstanceId,
            dutyItem.planOfCareItemId,
            dutyItem.notes !== '' ? dutyItem.notes : null,
            undefined
        )
            .then(() => {
                dutyItem.date = LocalDate.now().toString();
                dutyItem.type = "COORDINATOR";
                toaster.pop("success", "Successfully updated duty");
            })
            .catch(() => {
                toaster.pop("error", "Something went wrong");
            });
    }

    $scope.dutyItemsFilter = (dutyItem) => {
        if ($scope.editAndShowCompletedTasksOnly && dutyItem.completed === false) {
            return false;
        }
        return true;
    }

    $scope.saveChanges = (formName) => {
        switch ($scope.modalMode) {
            case ModalMode.Single:
                return $scope.saveSingleVisitInstanceChanges(formName);
            case ModalMode.BulkEdit:
                return $scope.saveBulkVisitInstancesChanges(formName);
            default:
                throw new Error(`Failed to saveChanges() for the mode "${$scope.modalMode}"`);
        }
    }

    $scope.handleChangeManualClockTimeEditApproved = () => {
        $scope.checkUpdateForm('visitInfoForm', 'manualClockTimeEditApproved');
        $scope.latestManualClockTimeEditApproved = $scope.visitInfoForm.manualClockTimeEditApproved;
    }

    $scope.saveBulkVisitInstancesChanges = (formName) => {
        if (!$scope[formName].agreeChanges) {
            return swalPleaseAgree();
        }

        const modalInstance = $uibModal.open({
            templateUrl: 'admin/views/accept-bulk-visit-instances-changes-modal.html',
            size: 'md',
            controller: 'acceptBulkVisitIInstancesChangesCtrl',
            windowClass: 'top-top',
            resolve: {
                total: () => visitInstanceIds.length,
                changes: () => ({ ...$scope.dirtyFields }),
                onApprove: () => handleApprove,
                onReject: () => handleReject,
                patientId:() => $scope.bulkUniquePatientId
            }
        });

        function handleApprove() {
            let text = "";
            let confirmButtonText = "Yes, save changes";
            let inputPlaceholder = undefined;
            let type = "warning";
            const isSomeRequireNoteVisits = $scope.bulkVisitInstances.some(visitInstance => visitInstance.isBilled || visitInstance.isPaid || visitInstance.isOnPayrollDraft);
            if (isSomeRequireNoteVisits) {
                const newScope = $scope.$new();
                newScope.note = {
                };

                $rootScope.openNewAdjustmentApprovalNoteModal(newScope).then((note) => {
                    if (note) {
                        saveBulkVisits(note);
                    }
                });
            }
            else {
                SweetAlert.swal({
                    title: "Are you sure you want to save changes?",
                    text: text,
                    type: type,
                    showCancelButton: true,
                    closeOnConfirm: true,
                    animation: "slide-from-top",
                    inputPlaceholder: inputPlaceholder,
                    html: true,
                    allowOutsideClick: false,
                    confirmButtonColor: "#3077EB",
                    confirmButtonText: confirmButtonText,
                }, function (isConfirm) {
                    if (isConfirm) {
                        saveBulkVisits();
                    }
                });
            }
        }

        function saveBulkVisits(approvalNote){
            const changes = $scope.dirtyFields;

            if ($scope.billingAndPayrollForm.payrollCodeId && $scope.billingAndPayrollForm.payrollCodeId === "no payroll code selected") {
                changes.payrollCodeId = undefined;
            }

            if (approvalNote) {
                changes.approvalNote = entityNoteService.buildEntityNoteRequest(
                    approvalNote,
                    noteConsts.NoteTypes.ADJUSTMENT_APPROVAL
                );
            }

            if (changes.missedVisitNote) {
                changes.missedVisitNote = entityNoteService.buildEntityNoteRequest(
                    changes.missedVisitNote,
                    noteConsts.NoteTypes.MISSED_VISIT_INSTANCE
                );
            }

            if (changes.distanceApprovalNote) {
                changes.distanceApprovalNote = entityNoteService.buildEntityNoteRequest(
                    changes.distanceApprovalNote,
                    noteConsts.NoteTypes.VISIT_DISTANCE_APPROVAL
                );
            }

            const url = wildcard(
                "agencies/:agencyId/agency_members/:agencyMemberId/bulk_edit_visit_instance",
                $rootScope.agencyId,
                $rootScope.agencyMemberId
            );
            const body = {
                ids: visitInstanceIds,
                changes: changes
            };
            $scope.isLoading++;

            DatabaseApi.post(url, body).then((res) => {
                $scope.isEdit[formName] = false;
                $rootScope.$broadcast('refresh_visits');
                initData();
                $scope.updateDirty(formName);
                resetForm(formName);

                if (res.data.overlapsCaregiver || res.data.overlapsPatient) {
                    const overlapType = res.data.overlapsCaregiver ? 'caregiver' : 'patient';
                    toaster.pop({
                        type: 'warning',
                        title: 'Warning',
                        body: `Visit was not updated due to overlap with other visits of the same ${overlapType}`,
                        timeout: 7000
                    });
                } else if (res.data.caregiverChangeBlockIds.length > 0) {
                    const caregiverChangeBlockIds = res.data.caregiverChangeBlockIds;
                    toaster.pop({
                        type: 'error',
                        title: 'Something went wrong',
                        body: `Visit${caregiverChangeBlockIds.length > 0 ? "s" : ""} was not updated due to billed or paid or clocked in/out: ${caregiverChangeBlockIds}`,
                        timeout: 2000
                    });
                }

				if (res.data.skippedVisitsAndMissingPermissions || res.data.caregiversWithNoValidHireDate.length > 0) {
					VisitInstanceModalService.showSkippedVisitsErrorModal({
                        skippedVisitsAndMissingPermissions: res.data.skippedVisitsAndMissingPermissions,
                        updatedVisitsAmount: res.data.visitInstances.length,
                        action: "update",
                        overlappingAmount: 0,
                        caregiversWithNoValidHireDate: res.data.caregiversWithNoValidHireDate
                    });
				} else if (!res.data.overlapsCaregiver && !res.data.overlapsPatient && res.data.caregiverChangeBlockIds.length === 0) {
                    if (res.data.assignWithIncreasedCaregiverOvertime) {
                        toaster.pop({
                            type: 'warning',
                            title: 'Warning',
                            body: `Visit${res.data.caregiverChangeBlockIds.length > 0 ? "s were" : " was"} updated with increased caregiver overtime`
                        });
                    } else {
                        toaster.pop('success', 'Successfully updated visit instances');
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                if (err.data && err.data.error === "Not permitted to increase caregiver overtime") {
                    toaster.pop('error', 'Something went wrong', err.data.error);
                } else if(err.data && err.data.error === "There are conflicts between this assignemnt and the office hours restrictions") {
                    $scope.openHoursRestrictionsErrorModal(err.data.conflicts);
                }
                else {
                    toaster.pop('error', 'Something went wrong');
                }
            })
            .finally(() => {
                $timeout(() => {
                    modalInstance.close();
                    $scope.isLoading--;
                }, 100);
            });
        }

        function handleReject() {
            modalInstance.close();
        }
    }

    $scope.isBulkMode = () => {
        return $scope.modalMode === ModalMode.BulkEdit;
    }

    function swalPleaseAgree() {
        return SweetAlert.swal({
            title: "Please agree and verify the changes",
            text: "",
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "OK",
            closeOnConfirm: true
        });
    }

    const simpleModal = ({ subject = "Error", message = "", variant = "danger", confirmLabel = "OK" }) => {
        const modal = mfModal.create({
            variant: variant,
            subject: subject,
            message: message,
            layoutOrder: ["message"],
            confirmLabel: confirmLabel,
            hideCancelButton: true,
            onComplete: () => modal.close()
        });
    }

    $scope.saveSingleVisitInstanceChanges = function (formName) {
        if (!$scope[formName].agreeChanges) {
            return swalPleaseAgree();
        }

        $scope[formName].missingParams = getMissingParams(formName);
        if ($scope[formName].missingParams) {
            return simpleModal({
                variant: "danger",
                subject: "Please fill in missing data",
                confirmLabel: "OK"
            });
        } else if (
            ($scope[formName].clockinTime !== null) &&
            ($scope[formName].clockoutTime != null) &&
            ($scope[formName].clockinTime > $scope[formName].clockoutTime)
        ) {
            return simpleModal({
                variant: "danger",
                subject: "Error",
                message: "Clock out time can't be earlier than clock in time",
                confirmLabel: "Oops, go back to editing"
            });
        }

        let confirmButtonText = "Yes, save changes";
        let type = "warning";

        const isApprovalNoteRequired =
          $scope.visit.isBilled || $scope.visit.isPaid || $scope.visit.isOnPayrollDraft;

        if ($rootScope.isOnboardingAgency && isApprovalNoteRequired) {
          const component = renderReactComponent({
            $element: angular.element(document.getElementById("visit-instance-content-container")),
            component: withAppOnly(EditVisitEntityNoteFormModal),
            props: {
              noteType: "visit_instance_edit",
              entity: { type: "Patient", id: $scope.visit.patient.id },
              visitInstanceId: $scope.visit.visitInfo.visitInstanceId,
              disclosure: {
                isOpen: true,
                onClose: () => {
                  $timeout(() => toaster.pop("warning", "Note canceled, changes not saved"));
                  component.unmount();
                },
              },
              isApprovalNoteRequired: isApprovalNoteRequired,
              onSubmit: ({ approvalNote }) => {
                $scope.saveSingleVisitInstanceChangesConfirmed(formName, { mfFields: { approvalNote }});
                component.unmount();
              },
            },
          });
          return;
        }

        if ($scope.visit.isBilled || $scope.visit.isPaid || $scope.visit.isOnPayrollDraft) {
            // need note approval
            const newScope = $scope.$new();
            newScope.note = {
                patientId: $scope.visit.patient.id,
                visitInstanceId: $scope.visit.visitInfo.visitInstanceId,
                caregiverId: $scope.visit.caregiver.id
            };

            $rootScope.openNewAdjustmentApprovalNoteModal(newScope).then((note) => {
                if (note) {
                    $scope.saveSingleVisitInstanceChangesConfirmed(formName, { approvalNote: note });
                }
            });
        } else {
            const modal = mfModal.create({
                subject: "Change Visit Info",
                variant: type,
                message: "Are you sure you want to save changes?",
                hideCancelButton: false,
                confirmLabel: confirmButtonText,
                onConfirm: () => $scope.saveSingleVisitInstanceChangesConfirmed(formName, {}),
                onComplete: () => modal.close()
            });
        }

    }

    $scope.saveSingleVisitInstanceChangesConfirmed = function(formName, { approvalNote, mfFields }){
        var form = $scope[formName];
        var defaultForm = resetForm(formName);
        var data = {};

        if (mfFields) {
            data = { ...mfFields };
        }

        if (approvalNote) {
            const actors = {
                patientId: $scope.visit.patient.id,
                caregiverId: $scope.visit.caregiverId ? $scope.visit.caregiverId : undefined
            };

            data.approvalNote = entityNoteService.buildEntityNoteRequest(
                approvalNote,
                noteConsts.NoteTypes.ADJUSTMENT_APPROVAL,
                actors
            );
        }
        for (var key in defaultForm) {
            if (defaultForm[key] === undefined || defaultForm[key] === null || (defaultForm[key] && !form[key]) || (form[key] !== undefined && defaultForm[key].valueOf() != form[key].valueOf())) {
                data[key] = form[key];
            }
        }

        if (defaultForm.patientConfirmedSchedule === null && form.patientConfirmedSchedule === null) {
            delete data.patientConfirmedSchedule;
        } else if (defaultForm.patientConfirmedSchedule !== form.patientConfirmedSchedule) {
            data.patientConfirmedSchedule = form.patientConfirmedSchedule ?? undefined;
        }

        delete data['agreeChanges'];
        delete data['missingParams'];
        if (formName === 'generalForm') {
            if (data.hasOwnProperty('caregiver')) {
                data.caregiverId = data.caregiver ? data.caregiver.id : null;
            }
           data.caregiverUnassignedNote = form.caregiverUnassignedNote;
        }
        else if (formName === 'visitInfoForm') {
            delete data['totalScheduledTime'];
            delete data['totalClockTime'];

            if ($scope.latestManualClockTimeEditApproved !== undefined) {
                data.manualClockTimeEditApproved = $scope.latestManualClockTimeEditApproved;
                $scope.latestManualClockTimeEditApproved = undefined;
            }

            if (data.hasOwnProperty("travelTimeMinutes")) data.travelTimeSeconds = data.travelTimeMinutes * 60;
            if (data.startTime) data.startTime = dateToJSJodaDate(data.startTime);
            if (data.endTime) data.endTime = dateToJSJodaDate(data.endTime);
            if (data.clockinTime || data.clockinTime === null) {
                data.clockinTime =
                    data.clockinTime !== null
                        ? dateToJSJodaDate(data.clockinTime)
                        : $scope.visit.clockInTime === null
                            ? undefined : null;
            } else {
                data.clockinTime = undefined;
            }
            if (data.clockoutTime || data.clockoutTime === null) {
                data.clockoutTime =
                    data.clockoutTime !== null
                        ? dateToJSJodaDate(data.clockoutTime)
                        : $scope.visit.clockOutTime === null
                            ? undefined : null;
            } else {
                data.clockoutTime = undefined;
            }
            if (
                $scope.visit.visitInfo.timesheetStatus === "PENDING" &&
                (
                    (
                        data.clockinTime !== undefined &&
                        $scope.visit.visitInfo.clockInUpdatedByUserType === "CAREGIVER" &&
                        $scope.visit.visitInfo.clockInMethod === "TIME_SHEET"
                    ) ||
                    (
                        data.clockoutTime !== undefined &&
                        $scope.visit.visitInfo.clockOutUpdatedByUserType === "CAREGIVER" &&
                        $scope.visit.visitInfo.clockOutMethod === "TIME_SHEET"
                    )
                )
            ) {
                return mfModal.createSimple({
                    variant: "danger",
                    subject: "Error",
                    message: [
                        "Clock in/out for this visit were submitted by the caregiver through the mobile's time sheet.",
                        "In order to proceed, please approve/decline Time Sheet bellow"
                    ].join(`\n`),
                    confirmLabel: "OK"
                });
            }
            if (data.manualClockNote) {
                const actors = {
                    patientId: $scope.visit.patient.id,
                    caregiverId: $scope.visit.caregiverId ? $scope.visit.caregiverId : undefined
                };

                data.manualClockNote = entityNoteService.buildEntityNoteRequest(
                    data.manualClockNote,
                    noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME,
                    actors
                );
            } else {
                data.manualClockNote = undefined;
            }
            if (data.distanceApprovalNote) {
                const actors = {
                    patientId: $scope.visit.patient.id,
                    caregiverId: $scope.visit.caregiverId ? $scope.visit.caregiverId : undefined
                };

                data.distanceApprovalNote = entityNoteService.buildEntityNoteRequest(
                    data.distanceApprovalNote,
                    noteConsts.NoteTypes.VISIT_DISTANCE_APPROVAL,
                    actors
                );
            } else {
                data.distanceApprovalNote = undefined;
            }
            if (data.missedVisitNote) {
                const actors = {
                    patientId: $scope.visit.patient.id,
                    caregiverId: $scope.visit.caregiverId ? $scope.visit.caregiverId : undefined
                };

                data.missedVisitNote = entityNoteService.buildEntityNoteRequest(
                    data.missedVisitNote,
                    noteConsts.NoteTypes.MISSED_VISIT_INSTANCE,
                    actors
                );
            } else {
                data.missedVisitNote = undefined;
            }
        } else if (formName === 'billingAndPayrollForm') {
            if (data.hasOwnProperty("billingAdjustmentMinutes")) data.billingAdjustmentSeconds = data.billingAdjustmentMinutes * 60;
            if (data.hasOwnProperty("payrollAdjustmentMinutes")) data.payrollAdjustmentSeconds = data.payrollAdjustmentMinutes * 60;
            if (data.patientContractId) {
                data.patientContractId = parseInt(data.patientContractId);
                data.serviceCodeId = form.serviceCodeId;
            }
            if (data.serviceCodeId) {
                data.serviceCodeId = parseInt(data.serviceCodeId);
                data.payrollCodeId = form.payrollCodeId;
            }
            if (data.payrollCodeId === 'no payroll code selected' || data.payrollCodeId === undefined) {
                data.payrollCodeId = null;
            }
            else if (data.payrollCodeId) {
                data.payrollCodeId = parseInt(data.payrollCodeId);
            }
        }

        if ($scope.shouldUnlinkClockIn !== undefined) {
            data.shouldUnlinkClockIn = $scope.shouldUnlinkClockIn;
        }
        if ($scope.shouldUnlinkClockOut !== undefined) {
            data.shouldUnlinkClockOut = $scope.shouldUnlinkClockOut;
        }

        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.visit.visitInfo.visitInstanceId
        );

        $scope.shouldDisplayLoading = true;
        $scope.promisesInProgress.push(DatabaseApi.put(url, data).then((res) => {
            const visitInstance = res.data.visitInstance;
            if (visitInstance) {
                if (formName === 'generalForm') {
                    if (visitInstance.caregiverId) {
                        DatabaseApi.get('agencies/' + $rootScope.agencyId + '/caregivers/' + visitInstance.caregiverId).then(function (res) {
                            $scope.visit.caregiver = res.data;
                            $scope.setCaregiver(res.data);
                        }, function (err) {
                            $scope.resetDefaultGeneralForm();
                        });
                    }
                    else if (!data.caregiverUnassignedNote) {
                        $scope.clearRn();
                    }
                } else if (formName === 'visitInfoForm') {
                    $scope.visit.date = new Date($scope.visit.startTime).toDateString();
                    $scope.visitInfoForm.startTime = new Date(visitInstance.startTime);
                    $scope.visitInfoForm.startTime.setSeconds(0, 0);
                    $scope.visitInfoForm.endTime = new Date(visitInstance.endTime);
                    $scope.visitInfoForm.endTime.setSeconds(0, 0);
                    if (visitInstance.clockinTime) {
                        $scope.visitInfoForm.clockinTime = new Date(visitInstance.clockinTime);
                        $scope.visitInfoForm.clockinTime.setSeconds(0, 0);
                    }
                    if (visitInstance.clockoutTime) {
                        $scope.visitInfoForm.clockoutTime = new Date(visitInstance.clockoutTime);
                        $scope.visitInfoForm.clockoutTime.setSeconds(0, 0);
                    }
                    $scope.updateTotalScheduledShiftTime();
                    $scope.updateTotalClockShiftTime();
                    $scope.visitInfoForm.missedVisit = visitInstance.missedVisit;

                    Object.assign($scope.visit, {
                        startTime: $scope.visitInfoForm.startTime,
                        endTime: $scope.visitInfoForm.endTime,
                        clockInTime: $scope.visitInfoForm.clockinTime,
                        clockOutTime: $scope.visitInfoForm.clockoutTime,
                        times: {
                            startTime: formatDateToTime($scope.visitInfoForm.startTime),
                            endTime: formatDateToTime($scope.visitInfoForm.endTime),
                            clockinTime: formatDateToTime($scope.visitInfoForm.clockinTime),
                            clockoutTime: formatDateToTime($scope.visitInfoForm.clockoutTime),
                            totalScheduledTime: $scope.visitInfoForm.totalScheduledTime,
                            totalClockTime: $scope.visitInfoForm.totalClockTime
                        }
                    });

                    if ($scope.visitInfoForm.startTime) $scope.visit.visitInfo.startTime = dateToJSJodaDate($scope.visitInfoForm.startTime);
                    if ($scope.visitInfoForm.endTime) $scope.visit.visitInfo.endTime = dateToJSJodaDate($scope.visitInfoForm.endTime);
                    if ($scope.visitInfoForm.clockinTime) $scope.visit.visitInfo.clockIn = dateToJSJodaDate($scope.visitInfoForm.clockinTime);
                    if ($scope.visitInfoForm.clockoutTime) $scope.visit.visitInfo.clockOut = dateToJSJodaDate($scope.visitInfoForm.clockoutTime);
                    $scope.visit.visitInfo.missedVisit = visitInstance.missedVisit;
                    $scope.visitInfoForm.missingParams = countVisitInfoMissingParams();
                    $rootScope.$broadcast('refresh_ptos');
                    $rootScope.$broadcast('refresh_timesheets');
                } else if (formName === 'billingAndPayrollForm') {
                    $scope.visit.visitInfo.patientContractId = visitInstance.patientContractId;
                    if (visitInstance.serviceCodeId && visitInstance.serviceCodeId !== 'no service code selected') {
                        let selectedServiceCode = $scope.serviceCodeArr.find(function (sc) {
                            return sc.id === visitInstance.serviceCodeId;
                        });
                        Object.assign($scope.visit, {
                            serviceCodeId: visitInstance.serviceCodeId,
                            serviceCodeCode: selectedServiceCode.code
                        });
                        $scope.visit.visitInfo.serviceCodeId = visitInstance.serviceCodeId;
                        $scope.billingAndPayrollForm.serviceCodeId = visitInstance.serviceCodeId;
                    }
                    if (visitInstance.payrollCodeId && visitInstance.payrollCodeId !== 'no payroll code selected') {
                        let selectedPayrollCode = $scope.payrollCodeArr.find(function (pc) {
                            return pc.id === visitInstance.payrollCodeId;
                        });
                        Object.assign($scope.visit, {
                            payrollCodeId: visitInstance.payrollCodeId,
                            payrollCode: selectedPayrollCode ? selectedPayrollCode.displayId : null
                        });
                        $scope.visit.visitInfo.payrollCodeId = visitInstance.payrollCodeId;
                    }
                    $scope.billingAndPayrollForm = $scope.resetDefaultBillingAndPayrollForm();
                    $scope.updatePayrollCodeCode();
                }
            }

            $scope.isEdit[formName] = false;
            $rootScope.$broadcast('refresh_visits');
            initData();
            $scope.shouldDisplayLoading = false
            $scope.updateDirty(formName);

            if (res.data.overlapsCaregiver || res.data.overlapsPatient) {
                const overlapType = res.data.overlapsCaregiver ? 'caregiver' : 'patient';
                toaster.pop({
                    type: 'warning',
                    title: 'Warning',
                    body: `Visit was not updated due to overlap with other visits of the same ${overlapType}`,
                    timeout: 7000
                });
            } else if (res.data.caregiverChangeBlocked === true) {
                toaster.pop({
                    type: 'error',
                    title: 'Something went wrong',
                    body: `Can't update assigned caregiver because the visit already billed or clocked in/out`,
                    timeout: 2000
                });
            } else if (res.data.caregiverWithNoValidHireDate) {
                toaster.pop({
                    type: 'warning',
                    title: 'Warning',
                    body: 'Visit was not updated because caregiver hire date is after the visit date'
                });
            }
            else if (res.data.assignWithIncreasedCaregiverOvertime) {
                toaster.pop({
                    type: 'warning',
                    title: 'Warning',
                    body: 'Visit was updated with increased caregiver overtime'
                });
            } else {
                toaster.pop('success', 'Successfully updated visit instance');
            }
        }, (err) => {
            $scope.shouldDisplayLoading = false
            $scope.isEdit[formName] = false;
            const dataErrorMessages = [
                "Not permitted to increase caregiver overtime",
                "Clock times must be within range of 24 hours from schedule times",
            ];

            if(err.data && err.status === 409 && err.data.conflicts){
                $scope.openHoursRestrictionsErrorModal(err.data.conflicts);
            } else if (err.data && err.data.error && dataErrorMessages.includes(err.data.error)) {
                toaster.pop('error', 'Something went wrong', err.data.error);
            }
            else if (err.data && err.data.permissions) {
                VisitInstanceModalService.showSkippedVisitsErrorModal({
                    skippedVisitsAndMissingPermissions: err.data,
                    updatedVisitsAmount: 0,
                    action: "update"
                });
            } else {
                toaster.pop('error', 'Something went wrong');
            }
            resetForm[formName];
            filterContractOptionsByAuthorizations();
        }));
    }

    const getDefaultFormByFormName = (formName) => {
        switch (formName) {
            case ("visitInfoForm"):
                return $scope.defaultVisitInfoForm;
            case ("generalForm"):
                return $scope.defaultGeneralForm;
            case ("billingAndPayrollForm"):
                return $scope.defaultBillingAndPayrollForm;
            default:
                throw new Error(`Failed to getDefaultFormByFormName() for the formName "${formName}"`);
        }
    };

    $scope.checkUpdateForm = function (formName, fieldName) {
        if ($scope.isEdit[formName]) {
            const defaultForm = getDefaultFormByFormName(formName);
            const isBulkMode = $scope.isBulkMode();
            $scope.dirtyFields = {};
            let changed = false;
            for (const key of Object.keys($scope[formName])) {
                if (key === 'agreeChanges') {
                    continue;
                }
                var updatedVal = JSON.stringify($scope[formName][key]);
                var defaultVal = JSON.stringify(defaultForm[key]);
                if (isBulkMode && $scope.bulkIndeterminateInputIds.includes(key)) {
                    const inputElement = $("#" + key)[0];
                    if (inputElement && !inputElement.indeterminate) {
                        changed = true;
                        $scope.dirtyFields[key] = $scope[formName][key];
                    }
                }
                else if (updatedVal !== defaultVal) {
                    changed = true;
                    $scope.dirtyFields[key] = $scope[formName][key];
                }
            }
            $scope.isEdit[formName + "Dirty"] = changed;
        }
    }

    $scope.isChangingInstaPayExemption = false;
    $scope.changeInstaPayExemption = function (value) {
        $scope.isChangingInstaPayExemption = true;

        const url = wildcard(
            "agencies/:agencyId/agency_members/:agencyMemberId/visit_instances/:visitInstanceId/insta_pay_rules_exempt",
            $rootScope.agencyId,
            $rootScope.agencyMemberId,
            $scope.visit.visitInfo.visitInstanceId
        );

        DatabaseApi.put(url, {
            exempt: value
        }).then((res) => {
            $scope.visit.visitInfoForm.instapayRulesExemption = res.data.exempt;
            $scope.visit.visitInfo.instapayRulesExemption = res.data.exempt;

            $scope.isChangingInstaPayExemption = false;
            toaster.pop('success', 'Successfully updated visit instance');
        }, (err) => {
            $scope.visit.visitInfoForm.instapayRulesExemption = $scope.visit.visitInfo.instapayRulesExemption;
            $scope.isChangingInstaPayExemption = false;
            toaster.pop('error', 'Something went wrong');
        });
    }

    $scope.updateDirty = function (formName) {
        $scope.isEdit[formName + "Dirty"] = false;
    }

    $scope.visitAuthorizationIsValid = function (visit) {
        if ($scope.totalAuthorizationMinutes(visit) * 60 !== visit.expectedRoundedBillableSeconds) {
            return false;
        }

        for (var i = 0; i < visit.matchingAuthorizations.length; i++) {
            if (!visit.matchingAuthorizations[i].valid) {
                return false;
            }
        }

        return true;
    }

    $scope.totalAuthorizationMinutes = function (visit) {
        var sum = 0;
        if (visit.matchingAuthorizations && visit.matchingAuthorizations.length > 0) {
            visit.matchingAuthorizations.forEach(function (auth) {
                sum += auth.minutesAllocated;
            });
        }
        return sum;
    }

    $scope.visitAuthorizationInputBlur = function (authorization) {
        if (authorization.hoursAllocated === undefined || authorization.hoursAllocated < 0) {
            authorization.hoursAllocated = 0;
        } else {
            authorization.hoursAllocated = Math.round(authorization.hoursAllocated * 4) / 4;
        }
    }

    $scope.goToInvoicePage = function (invoiceId) {
        $rootScope.openInvoiceModal({ invoiceId });
        $uibModalInstance.dismiss('dismiss');
    }

    $scope.goToInvoicesPageByBatchId = function (batchId) {
        var r = /\d+/;
        $state.go('app.billing.invoices', { batchId: batchId.match(r)[0] });
        $uibModalInstance.dismiss('dismiss');
    }

    $scope.goToPayrollBatchPage = function (payrollBatchId) {
        $uibModalInstance.dismiss('dismiss');
        $rootScope.closePatientModal();
        $rootScope.closeCaregiverModal();
        $timeout(() => $state.go('app.payroll.batch', { id: payrollBatchId }));
    }

    $scope.copyEndTimeToClockOut = function () {
        $scope.visitInfoForm.clockoutTime = angular.copy($scope.visitInfoForm.endTime);
        $scope.updateTotalClockShiftTime();
    }

    $scope.copyStartTimeToClockIn = function () {
        $scope.visitInfoForm.clockinTime = angular.copy($scope.visitInfoForm.startTime);
        $scope.updateTotalClockShiftTime();
    }

    $scope.submitVisitAuthorization = function () {
        var url =
            'agencies/' + $rootScope.agencyId +
            '/agency_members/' + $rootScope.agencyMemberId +
            '/visit_instances/' + $scope.visit.visitInstanceId +
            '/authorizations';

        var data = {
            authorizations: []
        };

        for (var i = 0; i < $scope.visit.matchingAuthorizations.length; i++) {

            data.authorizations.push({
                authorizationId: $scope.visit.matchingAuthorizations[i].authorizationSummary.authorizationId,
                minutesAllocated: Math.round($scope.visitAuthorizationForm.authorizations[i].hoursAllocated * 60)
            });
        }

        $scope.isEdit.authorization = false;
        $scope.visitAuthorizationLoading = true;

        $scope.promisesInProgress.push(DatabaseApi.put(url, data).then(function (res) {
            DatabaseApi.get(
                'agencies/' + $rootScope.agencyId +
                '/agency_members/' + $rootScope.agencyMemberId +
                '/visit_instances/' + visitInstanceId +
                '/billing_and_payroll'
            ).then(function (res) {
                $scope.visitAuthorizationLoading = false;

                Object.assign($scope.visit.matchingAuthorizations, res.data.matchingAuthorizations);

                $scope.visitAuthorizationForm.authorizations = [];
                $scope.visit.matchingAuthorizations.forEach(function (auth) {
                    $scope.visitAuthorizationForm.authorizations.push({
                        hoursAllocated: auth.minutesAllocated / 60
                    });
                });
                $scope.visit.isBilled = $scope.visit.invoiceId !== null;
                $scope.visit.isPaid = $scope.visit.payrollBatchId !== null;
                $scope.visit.isOnPayrollDraft = $scope.visit.isOnPayrollDraft === true;

                initPatientAuthorizations(res.data.allPatientAuthorizations);

                $rootScope.$emit('refresh_patient_authorizations');
                $rootScope.$emit("refresh_visits");
            }, function (err) {
                toaster.pop('error', 'Something went wrong');
            });

        }, function (res) {
            $scope.isEdit.authorization = false;
            $scope.visitAuthorizationLoading = false;

            const isExceedingRemainingAvailableHours = res.status === 409;

            if (isExceedingRemainingAvailableHours) {
                toaster.pop(
                    "error",
                    "Could not edit athorization hours.",
                    "The requested amount of hours exceeds the remaining hours available in the authorization."
                );
                return;
            }

            toaster.pop('error', 'Something went wrong');
        }));
    }

    $scope.significantClockinOffsetWatcher = () => {
        if (!$scope.visitInfoForm.clockinTime || !$scope.visitInfoForm.startTime) {
            return;
        }

        const offset = new Date($scope.visitInfoForm.clockinTime).getTime() - new Date($scope.visitInfoForm.startTime).getTime();
        const significantOffset = 1000 * 60 * 60;

        $scope.significantClockinOffset = Math.abs(offset) >= significantOffset ? offset : null;
    };

    $scope.significantClockoutOffsetWatcher = () => {
        if (!$scope.visitInfoForm.clockoutTime || !$scope.visitInfoForm.endTime) {
            return;
        }

        const offset = new Date($scope.visitInfoForm.clockoutTime).getTime() - new Date($scope.visitInfoForm.endTime).getTime();
        const significantOffset = 1000 * 60 * 60;

        $scope.significantClockoutOffset = Math.abs(offset) >= significantOffset ? offset : null;
    };

    $scope.$watchGroup(["visitInfoForm.clockinTime", "visitInfoForm.startTime"], $scope.significantClockinOffsetWatcher);
    $scope.$watchGroup(["visitInfoForm.clockoutTime", "visitInfoForm.endTime"], $scope.significantClockoutOffsetWatcher);

    $scope.handleChangeClockinTime = (newVal) => {
        $scope.visitInfoForm.clockinTime = newVal;
        $scope.significantClockinOffsetWatcher();
        $scope.updateTotalClockShiftTime();
    };

    $scope.handleChangeClockoutTime = (newVal) => {
        $scope.visitInfoForm.clockoutTime = newVal;
        $scope.significantClockoutOffsetWatcher();
        $scope.updateTotalClockShiftTime();
    };
    $scope.openManualClockChangesModal = function () {
        const noteSettings = entityNoteService.getAgencyEntityNoteSettingByType(noteConsts.NoteTypes.VISIT_MANUAL_CLOCK_TIME);
        if (!noteSettings) {
            return toaster.pop('error', 'No setting for ' + noteConsts.NoteTypesTranslations.VISIT_MANUAL_CLOCK_TIME);
        }

        $uibModal.open({
            templateUrl: "admin/views/visit-instance-manual-clock-changes.html",
            size: "lg",
            controller: "visitInstanceManualClockChangesModalCtrl",
            resolve: {
                reasons: function () {
                    return noteSettings.predefinedAnswers;
                },
                changes: function () {
                    return $scope.visit.visitInfo.manualClockChanges;
                },
                agencyMemberNamesDict: function () {
                    return DatabaseApi.getAgencyMembers();
                }
            }
        });
    }

    $scope.openClockDistanceApprovalChangesModal = function () {
        const noteSettings = entityNoteService.getAgencyEntityNoteSettingByType(noteConsts.NoteTypes.VISIT_DISTANCE_APPROVAL);
        if (!noteSettings) {
            return toaster.pop('error', 'No setting for ' + noteConsts.NoteTypesTranslations.VISIT_DISTANCE_APPROVAL);
        }

        $uibModal.open({
            templateUrl: "admin/views/visit-instance-clock-distance-approval-changes.html",
            size: "lg",
            controller: "visitInstanceManualClockChangesModalCtrl",
            resolve: {
                reasons: function () {
                    return noteSettings.predefinedAnswers;
                },
                changes: function () {
                    return $scope.visit.visitInfo.clockDistanceApprovalChanges.map(change => ({
                        ...change,
                        patientLocation: $scope.visit.patient.address.components.location
                    }));
                },
                agencyMemberNamesDict: function () {
                    return DatabaseApi.getAgencyMembers();
                }
            }
        });
    }

    var dateToJSJodaDate = function (date) {
        var joda = LocalDateTime.of(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            date.getHours(),
            date.getMinutes()
        );
        return joda.toString();
    }

    var resetForm = function (formName) {
        if (formName === 'generalForm') {
            $scope.generalForm = $scope.resetDefaultGeneralForm();
            return $scope.generalForm;
        }
        if (formName === 'visitInfoForm') {
            $scope.visitInfoForm = $scope.resetDefaultVisitInfoForm();
            return $scope.visitInfoForm;
        }
        if (formName === 'billingAndPayrollForm') {
            $scope.billingAndPayrollForm = $scope.resetDefaultBillingAndPayrollForm();
            return $scope.billingAndPayrollForm;
        }
    }

    function setNotesTableData(items) {
        var options = {
            count: 10,
            sorting: { id: "desc" }
        };

        $scope.notesTableData = new NgTableParams(options, {
            counts: [],
            dataset: items
        });
    }

    $scope.handleOpenNewNote = (callback) => {
        if (callback === undefined) {
            callback = (result) => {
                if (result === "OK") {
                    initData();
                }
            };
        }

        switch ($scope.modalMode) {
            case ModalMode.Single:
                return handleOpenSingleNewNote(callback);
            case ModalMode.BulkEdit:
                return handleOpenBulkNewNote(callback);
            default:
                throw new Error(`Failed to handleOpenNewNote() for the mode "${$scope.modalMode}"`);
        }
    }

    const handleOpenBulkNewNote = (callback) => {

        const newScope = $scope.$new();
        newScope.note = {
            type: noteConsts.NoteTypes.VISIT_INSTANCE,
            entitiesIds: $scope.bulkVisitInstances.map(visitInstance => visitInstance.visitInstanceId)
        };

        $rootScope.openNewVisitInstanceNoteModal(newScope).then(callback);
    }

    const handleOpenSingleNewNote = (callback) => {

        const newScope = $scope.$new();
        newScope.note = {
            patientId: $scope.visit.patient && $scope.visit.patient.id ? $scope.visit.patient.id : undefined,
            visitInstanceId: $scope.visit.visitInfo.visitInstanceId,
            caregiverId: $scope.visit.caregiver && $scope.visit.caregiver.id ? $scope.visit.caregiver.id : undefined,
        };

        $rootScope.openNewVisitInstanceNoteModal(newScope).then(callback);
    };

    $scope.closeModal = function () {
        $scope.$close({
            type: 'PROMISES_IN_PROGRESS',
            promises: $scope.promisesInProgress,
        });
    }

    $scope.$on('modal.closing', (event, reason, closed) => {
        if (closed) return;

        event.preventDefault();
        $scope.$close({
            type: 'PROMISES_IN_PROGRESS',
            promises: $scope.promisesInProgress,
        });
    });

    $scope.clockDistanceApprovedChanged = function () {
        if (
            $scope.defaultVisitInfoForm.clockDistanceApproved === $scope.visitInfoForm.clockDistanceApproved
        ) {
            $scope.isEdit.clockDistanceApproved = false;
        } else {
            $scope.isEdit.clockDistanceApproved = true;
        }
    }

    $scope.showClearClockTime = function (type) {
        if (type === "OUT")
            return (
                $scope.visitInfoForm.clockoutTime !== null
            );

        return (
            $scope.visitInfoForm.clockinTime !== null
        );
    };

    $scope.clearClockTime = function (type) {
        if (type === "OUT") {
            $scope.visitInfoForm.clockoutTime = null;
            $scope.updateTotalClockShiftTime();
            return;
        }

        $scope.visitInfoForm.clockinTime = null;
        $scope.updateTotalClockShiftTime();
    };

    $scope.canEditAddress = () => {
        switch ($scope.modalMode) {
            case ModalMode.Single:
                return true;
            case ModalMode.BulkEdit:
                const uniquePatientIds = new Set($scope.bulkVisitInstances.map(visitInstance => visitInstance.patientId));
                return uniquePatientIds.size === 1;
        }
    }

    $scope.copyToClipboard = (val) => {
        var copyText = document.createElement("TEXTAREA");
        var t = document.createTextNode(val);
        copyText.appendChild(t);
        copyText.style.height = '0px';
        document.body.appendChild(copyText);
        copyText.select();
        document.execCommand("copy");
        toaster.pop("success", "Copied to clipboard");
    };

    $scope.unlinkClockRecord = (clockType) => {
        $scope.clearClockTime(clockType);

        if (clockType === "OUT") {
            $scope.shouldUnlinkClockOut = true;
        }

        if (clockType === "IN") {
            $scope.shouldUnlinkClockIn = true;
        }
    }

    $scope.openPotentialClockRecords = (possibilities, clockType) => {
        const modalInstance = $uibModal.open({
            templateUrl: "admin/views/visit-instance-potential-clock-recods.html",
            size: "lg",
            controller: "visitInstancePotentialClockRecords",
            windowClass: "center-center",
            resolve: {
                possibilities: () => possibilities,
                onLink: () => () => {
                    initData();
                    $rootScope.$broadcast('refresh_visits');
                },
                clockType: () => clockType
            }
        });
    }



    $scope.addAdjustmentApprovalsData = (visit)=>{

        visitInstanceService.getVisitInstanceAdjustmentApprovals(visit.visitInfo.visitInstanceId).then((res)=>{
            $scope.visit.adjustments = res.data.adjustments;
            $scope.visit.pendingAdjustments = res.data.adjustments.filter(row => row.approvalStatus === null);
        });

    }
    $scope.handleNoteChanges = (noteModel, updatedNote) => {
        if (!$scope.visitInfoForm[noteModel]) {
            $scope.visitInfoForm[noteModel] = {};
        }

        $scope.visitInfoForm[noteModel] = updatedNote;
        $scope.dirtyFields[noteModel] = $scope.visitInfoForm[noteModel];
        $scope.isEdit.visitInfoFormDirty = true;
    };

    const initPatientAuthorizations = (authorizations) => {
        let filteredAuthorizations = [];
        if ($scope.modalMode === ModalMode.BulkEdit) {
            const firstVisitLocalDate = $scope.bulkVisitInstances
                .map(visit => LocalDateTime.parse(visit.startTime).toLocalDate())
                .sort((a, b) => a.isAfter(b) ? 1 : -1)[0];
            filteredAuthorizations = authorizations.filter(auth =>
                auth.endDate === null ||
                auth.endDate === undefined ||
                LocalDate.parse(auth.endDate).isAfter(firstVisitLocalDate)
            );
        } else {
            const visitLocalDate = LocalDate.parse($scope.visit.visitDate);
            filteredAuthorizations = authorizations.filter(auth =>
                auth.authorizationSummary.endDate === null ||
                auth.authorizationSummary.endDate === undefined ||
                LocalDate.parse(auth.authorizationSummary.endDate).isAfter(visitLocalDate)
            );
        }

        const contractServiceCodesMap = {};
        let serviceCodeIds = [];
        filteredAuthorizations.forEach(auth => {
            const contractId = auth.patientContractId;
            const serviceCodeId = auth.serviceCodeId || auth.serviceCode;
            if (contractServiceCodesMap[contractId] === undefined) {
                contractServiceCodesMap[contractId] = [serviceCodeId];
            } else if (!contractServiceCodesMap[contractId].includes(serviceCodeId)) {
                contractServiceCodesMap[contractId].push(serviceCodeId);
            }
            serviceCodeIds.push(serviceCodeId);
        });

        const contractIds = Object.keys(contractServiceCodesMap).map(x => Number(x));
        serviceCodeIds = [...new Set(serviceCodeIds)];

        $scope.authorizations = {
            contractServiceCodesMap: contractServiceCodesMap,
            contractIds: contractIds,
            serviceCodeIds: serviceCodeIds,
            isFiltering: $scope.authorizations === undefined ? true : $scope.authorizations.isFiltering,
        };

        $scope.toggleAuthorizationsFilter = () => {
            if ($scope.authorizations === undefined) {
                return;
            }
            $scope.authorizations.isFiltering = !$scope.authorizations.isFiltering;
            filterContractOptionsByAuthorizations();
        };
    };

    const filterContractOptionsByAuthorizations = () => {
        $scope.contractsOptions = angular.copy($scope.allContractsOptions);
        $scope.contractsOptions.forEach(contractOption => {
            if (contractOption.id === $scope.originalContractId) {
                contractOption.label = `${contractOption.contractTypeName} (current)`;
            }
        });
        if (
            $scope.authorizations !== undefined &&
            $scope.authorizations.isFiltering &&
            $scope.authorizations.contractIds.length > 0
        ) {
            $scope.contractsOptions = $scope.contractsOptions.filter(contractOption =>
                $scope.authorizations.contractIds.includes(contractOption.id)
                || contractOption.id === $scope.originalContractId
            );
        }
        if ($scope.contractsOptions.length === 1) {
            $scope.billingAndPayrollForm.patientContractId = $scope.contractsOptions[0].id;
        } else if (
            $scope.contractsOptions.find(contractOption =>
                contractOption.id === $scope.billingAndPayrollForm.patientContractId
            ) === undefined
        ) {
            $scope.billingAndPayrollForm.patientContractId = 'no contract selected';
        }
        $scope.updateServiceCodeOptions($scope.billingAndPayrollForm.patientContractId);
        $scope.checkUpdateForm('billingAndPayrollForm', 'patientContractId')
    };

    $scope.togglEditAndShowDutyTasks = () => {
        $scope.editAndShowCompletedTasksOnly = !$scope.editAndShowCompletedTasksOnly;
    };

    $scope.changeTimesheetStatus = (newStatus) => {
        if (
            $scope.visit.visitInfo.timesheetStatus !== 'PENDING' ||
            (
                $scope.visit.visitInfo.clockInMethod !== 'TIME_SHEET' &&
                $scope.visit.visitInfo.clockOutMethod !== 'TIME_SHEET'
            )
        ) {
            return;
        }

        const timesheetData = {
            timesheetId: $scope.visit.visitInfo.timesheetId,
            caregiverId: $scope.visit.caregiver.id,
            patientId: $scope.visit.patient.id,
            visitInstanceId: visitInstanceId
        };
        
        switch (newStatus) {
            case "APPROVED":
                return approveTimesheet(timesheetData);
            case "DECLINED":
                return declineTimesheet(timesheetData);
            default:
                console.error("No TimesheetStatus case defined to status " + newStatus);
        }
    };

    const approveTimesheet = (timesheetData) => {
        $scope.isLoading = 1;
        $scope.shouldDisplayLoading = true;
        return timesheetsService.editTimesheetsStatus({
            timesheets: [timesheetData],
            onSuccess: (res) => {
                toaster.pop("success", "Success", "Timesheet approved successfully");
                initData();
            },
            onCatch: (err) => mfModal.createSimple({
                variant: "danger",
                subject: "Error",
                message: "Failed to approve timesheet",
                confirmLabel: "OK"
            }),
            onFinally: () => {
                $scope.isLoading = 0;
                $scope.shouldDisplayLoading = false;
            }
        }, "APPROVED");
    };

    const declineTimesheet = (timesheetData) => {
        $scope.isLoading = 1;
        $scope.shouldDisplayLoading = true;
        return timesheetsService.editTimesheetsStatus({
            timesheets: [timesheetData],
            onSuccess: (res) => {
                toaster.pop("success", "Success", "Timesheet declined successfully");
                initData();
            },
            onCatch: (err) => mfModal.createSimple({
                variant: "danger",
                subject: "Error",
                message: "Failed to decline timesheet",
                confirmLabel: "OK"
            }),
            onFinally: () => {
                $scope.isLoading = 0;
                $scope.shouldDisplayLoading = false;
            }
        }, "DECLINED");
    };

    $scope.visitPatient = (id) => {
        $rootScope.openPatientModal(id);
    }
    
    $scope.visitCaregiver = (id) => {
        $rootScope.openCaregiverModal(id);
    }

    $scope.onCloseHoursRestrictionErrorModal = () => {
        $timeout(() => {
            $scope.visitHoursRestrictionError = {
                error: false,
                conflicts: []
            };
        });
    };

    $scope.openHoursRestrictionsErrorModal = (conflicts) => {
        $timeout(() => {
            $scope.visitHoursRestrictionError = {
                error: true,
                conflicts
            };
        })
    };

    initData();
};
