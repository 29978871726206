import { PatientExtendedData } from "../messages/patient";
import { PatientPlanOfCare } from "../messages/plan_of_care";
import { PlanOfCareDutyTableRow, PlanOfCareService } from "../services/planOfCareService";
import {
  InitialFormData,
  mapPatientPlansOfCareDutiesToFormDuties,
  NewPlanOfCareModalCtrl,
} from "./newPlanOfCareModalCtrl";

type Patient = Pick<
  PatientExtendedData,
  "id" | "currentOfficeId" | "firstName" | "middleName" | "lastName"
>;

interface Bindings {
  patient: Patient;
  planOfCare: PatientPlanOfCare;
}

interface ComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof Bindings, string>;
}

//! @ngInject
class PatientPlanOfCareCtrl implements ng.IComponentController, Bindings {
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $uibModal: angular.ui.bootstrap.IModalService,
    private planOfCareService: PlanOfCareService,
    private toaster: toaster.IToasterService,
    private NgTableParams: NgTable.ITableParamsConstructor<any>
  ) {}

  //#region bindings
  patient!: Patient;
  planOfCare!: PatientPlanOfCare;
  //#endregion

  //#region template variables
  readonly checked = true;
  //#endregion

  table: NgTableParams<PlanOfCareDutyTableRow> | null = null;

  $onChanges(onChangesObj: ng.IOnChangesObject) {
    if (onChangesObj.planOfCare) {
      this.setTable(
        this.planOfCare.duties.map((duty) => this.planOfCareService.mapToTableRow(duty))
      );
    }
  }

  handleClickNewPlanOfCare() {
    this.$uibModal
      .open({
        templateUrl: "admin/views/new-plan-of-care-modal.html",
        size: "lg",
        controller: NewPlanOfCareModalCtrl.$name,
        controllerAs: "ctrl",
        resolve: {
          initialFormData: (): InitialFormData | null => ({
            startDate: this.planOfCare.endDate?.plusDays(1),
            duties: mapPatientPlansOfCareDutiesToFormDuties(this.planOfCare.duties),
            selectedPlanOfCare: this.planOfCare,
          }),
          patientId: this.patient.id,
          patientOfficeId: this.patient.currentOfficeId,
        },
      })
      .result.then((res) => {
        if (res === "success") {
          this.$rootScope.$emit("patient_poc_updated");
        }
      });
  }

  handleClickSendEmail() {
    const patientFullName = [this.patient.firstName, this.patient.middleName, this.patient.lastName]
      .filter(Boolean)
      .join(" ");

    this.$uibModal.open({
      templateUrl: "admin/views/email-modal.html",
      size: "lg",
      resolve: {
        documents: () => [{ id: this.planOfCare.planOfCareId }],
        patientId: () => this.patient.id,
        patientFullName: () => patientFullName,
        docsType: () => "planOfCare",
      },
      controller: "emailModalCtrl",
    });
  }

  handleClickViewPDF() {
    this.planOfCareService
      .getPdfUrl(this.planOfCare.planOfCareId)
      .then((pdfUrl) => {
        if (pdfUrl === null) {
          this.toaster.warning("No PDF available");
          return;
        }

        window.open(pdfUrl);
      })
      .catch((error) => {
        console.error(error);
        this.toaster.error({ body: "Error while loading pdf" });
      });
  }

  handleClickRegeneratePDF() {
    this.planOfCareService
      .regeneratePdf(this.planOfCare.planOfCareId)
      .then((pdfUrl) => {
        if (pdfUrl === null) {
          this.toaster.warning("Failed to regenerate PDF");
          return;
        }

        window.open(pdfUrl);
      })
      .catch((error) => {
        console.error(error);
        this.toaster.error({ body: "Error while regenerating pdf" });
      });
  }

  setTable(items: PlanOfCareDutyTableRow[]) {
    this.table = new this.NgTableParams(
      {
        count: 15,
      },
      {
        counts: [15, 25, 50, 100],
        dataset: items,
      }
    );
  }
}

export const patientPlanOfCareComponent: ComponentOptions = {
  templateUrl: "admin/views/patient-plan-of-care.html",
  controller: PatientPlanOfCareCtrl,
  controllerAs: "ctrl",
  bindings: {
    patient: "<",
    planOfCare: "<",
  },
};
